import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';

import {GridContainer, ItemGrid} from '../../material-dashboard-pro-react/components';
import {
  DirectoryServicesTab,
  EntraIDTab,
  RenderOrEmpty,
  TabsPanel,
  UsersSettingsTab,
} from '../../components';
import {updateBaseSetting} from './action';
import {STATES_ENTITY} from '../../constants';

import style from './style';

function PersonasEditForm({classes, isEditMode, getPolicies, domains, tenants}) {
  const dispatch = useDispatch();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const baseSettings = useSelector((store) => store.personaEditFormReducer.baseSettingsObject);
  const entraTenantId = useSelector((store) => store.personaEditFormReducer.entraId.entraTenantId);
  const domainId = useSelector((store) => store.personaEditFormReducer.directoryServices.domainId);
  const personaState = useSelector((store) => store.personaReducer.personaState);

  const handleClickTab = (tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const handleSettingsChange = (newSettings) => {
    dispatch(updateBaseSetting(newSettings));
  };

  const getADTabOrNothing = () => ([{
    text: I18n.t('users.usersConfigurer.persona.directoryServices.tabName'),
    hidden: !(domainId || (!domainId && !entraTenantId)),
    content: <DirectoryServicesTab
          domains={domains}
          isEditMode={isEditMode}
    />,
  }]);

  const getEntraTabOrNothing = () => ([{
    text: I18n.t('users.usersConfigurer.persona.entraId.tabName'),
    hidden: !(entraTenantId || (!domainId && !entraTenantId)),
    content: <EntraIDTab
        tenants={tenants}
        isEditMode={isEditMode}
    />,
  }]);

  const tabs = [
    {
      text: I18n.t('users.usersConfigurer.persona.settings.tabName'),
      content: <UsersSettingsTab
        baseSettings={baseSettings}
        handleSettingsChange={handleSettingsChange}
        isEditMode={isEditMode}
      />,
    },
    ...getADTabOrNothing(),
    ...getEntraTabOrNothing(),
  ];

  useEffect(() => {
    dispatch(getPolicies());
  }, []);

  useEffect(() => {
    if (personaState === STATES_ENTITY.CREATING && !domainId && !entraTenantId) {
      setActiveTabIndex(0);
    } else if (domainId) {
      setActiveTabIndex(1);
    } else if (entraTenantId) {
      setActiveTabIndex(2);
    }
  }, [personaState, entraTenantId, domainId]);

  return (
    <GridContainer direction="column" className={classes.grid}>
      <ItemGrid>
        <TabsPanel
          activeTabIndex={activeTabIndex}
          disabled={false}
          handleClickTab={handleClickTab}
          tabs={tabs}
        />
      </ItemGrid>
      <RenderOrEmpty isShow={!!tabs[activeTabIndex]}>
        <ItemGrid>
          {tabs[activeTabIndex]?.content ?? ''}
        </ItemGrid>
      </RenderOrEmpty>
    </GridContainer>
  );
}

PersonasEditForm.propTypes = ({
  classes: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  getPolicies: PropTypes.func.isRequired,
  domains: PropTypes.array.isRequired,
  tenants: PropTypes.array.isRequired,
});

export default withStyles(style)(PersonasEditForm);
