import 'isomorphic-fetch';
import AbortController from 'abort-controller';
import {v4 as uuid} from 'uuid';
import {I18n} from 'react-redux-i18n';
import _isUndefined from 'lodash/isUndefined';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import {getJWT, getRefresh, isSSO} from './jwt';

import {
  endLoading,
  startLoading,
} from '../containers/Loading/action';

import {
  closeModalWindow,
  openModalWindow,
} from '../scout-dns/layouts/Operator/action';

import {logoutSuccess} from '../actions/auth/logout';
import {refreshToken} from '../actions/auth/refreshToken';

import {
  MODAL_WINDOW_NOTIFY_TYPE,
  FETCHER_ERRORS,
  DEFAULT_WIDGET_KEY,
  ROUTES,
  METHODS_WITH_NO_TOKEN_REFRESH_ON_FAILURE,
} from '../constants';

import browserHistory from '../history';

export const buildQueryParams = (paramsMap) => {
  const keys = Object.keys(paramsMap);
  const validParams = [];
  keys.forEach((key) => {
    if (paramsMap[key] !== null) {
      validParams.push({
        key: key,
        value: paramsMap[key],
      });
    }
  });
  let queryParams = '';
  if (validParams.length === 1) {
    return `${validParams[0].key}=${validParams[0].value}`;
  }
  validParams.forEach((param) => {
    queryParams = `${queryParams}&${param.key}=${param.value}`;
  });
  return queryParams;
};

export const makeUrlToGetMock = (relativeUrl, queryParams) => {
  const urlPrefix = '/mockapi/operator/';

  if (!_isUndefined(queryParams)) {
    const queryString = buildQueryParams(queryParams);
    return encodeURI(`${urlPrefix}${relativeUrl}?${queryString}`);
  }
  return encodeURI(`${urlPrefix}${relativeUrl}`);
};

export const makeUrl = (relativeUrl, queryParams) => {
  const urlPrefix = process.env.REACT_APP_OPERATOR_API_LOCATION;

  if (!_isUndefined(queryParams)) {
    const queryString = buildQueryParams(queryParams);
    return encodeURI(`${urlPrefix}${relativeUrl}?${queryString}`);
  }
  return encodeURI(`${urlPrefix}${relativeUrl}`);
};

export const makeUrlForResource = (relativeUrl, resourceName) => {
  const urlPrefix = process.env.REACT_APP_FILES_LOCATION;

  if (!_isUndefined(resourceName)) {
    return encodeURI(`${urlPrefix}${relativeUrl}/${resourceName}`);
  }
  return encodeURI(`${urlPrefix}${relativeUrl}`);
};

let checkStatusDelayStartTime = 0;
const CHECK_STATUS_DELAY = 10000;

const checkStatus = (response, dispatch) => {
  if (response.ok) {
    return Promise.resolve(response);
  }
  if (response.status === 403 || response.status === 401) {
    // reject all requests with 403 or 401 after first request with 403 or 401
    // within CHECK_STATUS_DELAY milliseconds
    if (CHECK_STATUS_DELAY - (Date.now() - checkStatusDelayStartTime) > 0) {
      return Promise.reject(response);
    }
    checkStatusDelayStartTime = Date.now();
    dispatch(closeModalWindow());
    const isAllowedWithoutAuth = METHODS_WITH_NO_TOKEN_REFRESH_ON_FAILURE
      .reduce((accumulator, method) => accumulator || response.url.endsWith(method), false);
    if (!isAllowedWithoutAuth) {
      const isSSOAccount = isSSO();
      dispatch(refreshToken(getRefresh(), getJWT())).then((success) => {
        if (!success) {
          if (isSSOAccount) browserHistory.push(ROUTES.LOGOUT_SSO);
          else browserHistory.push(ROUTES.LOGIN);
        } else {
          window.location.reload();
        }
      });
    }
    return Promise.reject(response);
  }

  return response.json()
    .then((json) => Promise.reject(json));
};

// Check status after request to external third-party API
const checkExternalStatus = (response) => {
  if (response.status === 200 || response.status === 201) {
    return Promise.resolve(response);
  }
  return Promise.reject(response);
};

const checkResourceStatus = (response) => {
  if (response.ok) {
    return Promise.resolve(response);
  }

  return Promise.reject(response);
};

const checkResult = (response) => {
  if (response && response.success) {
    return Promise.resolve(response);
  }
  return Promise.reject(response);
};

const checkResultCsv = (response) => {
  if (response && response.ok) {
    return Promise.resolve(response);
  }
  return Promise.reject(response);
};

// Check for response from external API
const checkExternalResult = (response) => {
  if (response) {
    return Promise.resolve(response);
  }
  return Promise.reject(response);
};

const getHeaders = (isMultipartData = false, includeJwt = true) => {
  const jwt = getJWT();
  const headers = {
    Accept: 'application/json',
  };

  if (!isMultipartData) headers['Content-Type'] = 'application/json';

  if (includeJwt && jwt) {
    headers['X-AUTH-TOKEN'] = jwt;
  }
  return new Headers(headers);
};

const getResourceHeaders = () => {
  const jwt = getJWT();
  const headers = {
    'Accept': 'application/octet-stream',
    'Content-Type': 'application/json',
  };
  if (jwt) {
    headers['X-AUTH-TOKEN'] = jwt;
  }
  return new Headers(headers);
};

const getCsvHeaders = () => {
  const jwt = getJWT();
  const headers = {
    'Accept': 'application/csv',
    'Content-Type': 'application/json',
  };
  if (jwt) {
    headers['X-AUTH-TOKEN'] = jwt;
  }
  return new Headers(headers);
};

export const getWithInterrupt = (
  url,
  dispatch,
  useModalWindowForError = true,
  widgetKey = DEFAULT_WIDGET_KEY,
) => {
  const options = {
    method: 'GET',
    headers: getHeaders(),
    mode: 'cors',
    credentials: 'include',
  };

  let isInterrupted = false;
  let _useModalWindowForError = useModalWindowForError;
  const abortController = new AbortController();
  const signal = abortController.signal;

  const loadingUUID = uuid();
  startLoading(dispatch, loadingUUID, abortController, widgetKey, url);

  const request = fetch(url, {...options, signal})
    .then((response) => {
      // TODO: eslint warning
      // console.log('Interrupted: ', isInterrupted);
      if (isInterrupted) {
        _useModalWindowForError = false;
        return Promise.reject(new Error('Request was interrupted'));
      }
      return response;
    })
    .then((response) => checkStatus(response, dispatch))
    .then((response) => response.json())
    .then((response) => checkResult(response))
    .then((response) => {
      endLoading(dispatch, loadingUUID, widgetKey);
      return response;
    })
    .catch((error) => {
      processError(error, dispatch, _useModalWindowForError, loadingUUID, widgetKey);
      return error;
    });

  return {
    request: request,
    interrupt: () => {
      isInterrupted = true;
      abortController.abort();
      endLoading(dispatch, loadingUUID, widgetKey);
    },
  };
};

export const getExternalWithInterrupt = (
  url,
  dispatch,
  widgetKey = DEFAULT_WIDGET_KEY,
) => {
  const options = {
    method: 'GET',
    headers: getHeaders(false, false),
  };

  let isInterrupted = false;
  const abortController = new AbortController();
  const signal = abortController.signal;

  const loadingUUID = uuid();
  startLoading(dispatch, loadingUUID, abortController, widgetKey, url);

  const request = fetch(url, {...options, signal})
    .then((response) => {
      if (isInterrupted) {
        return Promise.reject(new Error('Request was interrupted'));
      }
      return response;
    })
    .then((response) => checkExternalStatus(response, dispatch))
    .then((response) => response.json())
    .then((response) => checkExternalResult(response))
    .then((response) => {
      endLoading(dispatch, loadingUUID, widgetKey);
      return response;
    })
    .catch((error) => {
      endLoading(dispatch, loadingUUID, widgetKey);
      return error;
    });

  return {
    request: request,
    interrupt: () => {
      isInterrupted = true;
      abortController.abort();
      endLoading(dispatch, loadingUUID, widgetKey);
    },
  };
};

export const getResourceWithInterrupt = (
  url,
  dispatch,
  errorMessageObject,
  useModalWindowForError = true,
  widgetKey = DEFAULT_WIDGET_KEY,
) => {
  const options = {
    method: 'GET',
    headers: getResourceHeaders(),
  };

  let isInterrupted = false;
  let _useModalWindowForError = useModalWindowForError;
  const abortController = new AbortController();
  const signal = abortController.signal;

  const loadingUUID = uuid();
  startLoading(dispatch, loadingUUID, abortController, widgetKey, url);
  const request = fetch(url, {...options, signal})
    .then((response) => {
      // TODO: eslint warning
      // console.log('Interrupted: ', isInterrupted);
      if (isInterrupted) {
        _useModalWindowForError = false;
        return Promise.reject(new Error('Request was interrupted'));
      }
      return response;
    })
    .then((response) => checkResourceStatus(response, dispatch))
    .then((response) => {
      endLoading(dispatch, loadingUUID, widgetKey);
      return response;
    })
    .catch((error) => {
      processResourceError(
        error,
        dispatch,
        _useModalWindowForError,
        loadingUUID,
        widgetKey,
        errorMessageObject,
      );
      return error;
    });

  return {
    request: request,
    interrupt: () => {
      isInterrupted = true;
      abortController.abort();
      endLoading(dispatch, loadingUUID, widgetKey);
    },
  };
};

export const get = (
  url,
  dispatch,
  useModalWindowForError = true,
  widgetKey = DEFAULT_WIDGET_KEY,
) => {
  const requestInfo = getWithInterrupt(url, dispatch, useModalWindowForError, widgetKey);
  return requestInfo.request;
};

// Get request for external third-party API
export const getExternal = (
  url,
  dispatch,
  widgetKey = DEFAULT_WIDGET_KEY,
) => {
  const requestInfo = getExternalWithInterrupt(url, dispatch, widgetKey);
  return requestInfo.request;
};

export const post = (
  url,
  data,
  dispatch,
  useModalWindowForError = true,
  widgetKey = DEFAULT_WIDGET_KEY,
  isMultipartData = false,
  errorMessage = null,
) => {
  const options = {
    method: 'POST',
    headers: getHeaders(isMultipartData),
    body: isMultipartData ? data : JSON.stringify(data),
    mode: 'cors',
    credentials: 'include',
  };

  const abortController = new AbortController();
  const signal = abortController.signal;
  const loadingUUID = uuid();
  startLoading(dispatch, loadingUUID, abortController, widgetKey, url);

  return fetch(url, {...options, signal})
    .then((response) => checkStatus(response, dispatch))
    .then((response) => response.json())
    .then((response) => checkResult(response))
    .then((response) => {
      endLoading(dispatch, loadingUUID, widgetKey);
      return response;
    })
    .catch((error) => {
      processError(error, dispatch, useModalWindowForError, loadingUUID, widgetKey, errorMessage);
      throw error;
    });
};

export const postWithCsvResponse = (
  url,
  data,
  dispatch,
  useModalWindowForError = true,
  widgetKey = DEFAULT_WIDGET_KEY,
  errorMessage = null,
) => {
  const options = {
    method: 'POST',
    headers: getCsvHeaders(),
    body: JSON.stringify(data),
    mode: 'cors',
    credentials: 'include',
  };

  const abortController = new AbortController();
  const signal = abortController.signal;
  const loadingUUID = uuid();

  let contentDispositionHeader;

  startLoading(dispatch, loadingUUID, abortController, widgetKey, url);

  return fetch(url, {...options, signal})
    .then((response) => checkResultCsv(response))
    .then((response) => {
      contentDispositionHeader = response.headers.get('Content-Disposition');
      return response.blob();
    })
    .then((blob) => {
      endLoading(dispatch, loadingUUID, widgetKey);
      const blobUrl = URL.createObjectURL(blob);

      const regex = /filename=([^;]+)/;
      const match = regex.exec(contentDispositionHeader);

      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = match && match[1] ? match[1] : `${uuid()}.csv`;
      document.body.appendChild(a);

      a.click();

      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(a);
    })
    .catch((error) => {
      processError(error, dispatch, useModalWindowForError, loadingUUID, widgetKey, errorMessage);
      throw error;
    });
};

function processError(
  error,
  dispatch,
  useModalWindowForError,
  loadingUUID,
  widgetKey,
  errorMessage,
) {
  if (error.status === 403) {
    // TODO: Maybe use another action?
    dispatch(logoutSuccess());
    return error;
  }
  if (useModalWindowForError) {
    if (errorMessage != null) {
      dispatch(openModalWindow(
        errorMessage,
        MODAL_WINDOW_NOTIFY_TYPE.INFO,
      ));
    } else {
      switch (error.name) {
        case FETCHER_ERRORS.SYNTAX: {
          dispatch(openModalWindow(
            I18n.t('systemErrors.internalServerError'),
            MODAL_WINDOW_NOTIFY_TYPE.INFO,
          ));
          break;
        }
        case FETCHER_ERRORS.ABORTED: {
          break;
          // no action
        }
        default: {
          showError(error, dispatch);
        }
      }
    }
  }
  endLoading(dispatch, loadingUUID, widgetKey);
}

function processResourceError(
  error,
  dispatch,
  useModalWindowForError,
  loadingUUID,
  widgetKey,
  errorMessageObject,
) {
  if (useModalWindowForError && errorMessageObject != null) {
    let found = false;
    for (const [key, value] of Object.entries(errorMessageObject)) {
      if (error.status.toString() === key.toString()) {
        found = true;
        dispatch(openModalWindow(
          value,
          MODAL_WINDOW_NOTIFY_TYPE.INFO,
        ));
      }
    }
    if (!found && errorMessageObject[0] != null) {
      dispatch(openModalWindow(
        errorMessageObject[0],
        MODAL_WINDOW_NOTIFY_TYPE.INFO,
      ));
    }
  } else if (useModalWindowForError) {
    switch (error.name) {
      case FETCHER_ERRORS.SYNTAX: {
        dispatch(openModalWindow(
          I18n.t('systemErrors.internalServerError'),
          MODAL_WINDOW_NOTIFY_TYPE.INFO,
        ));
        break;
      }
      case FETCHER_ERRORS.ABORTED: {
        break;
        // no action
      }
      default: {
        showError(error, dispatch);
      }
    }
  }
  endLoading(dispatch, loadingUUID, widgetKey);
}

function showError(error, dispatch) {
  let errorMessage = _get(error, 'errorMessage', null);
  if (!errorMessage) {
    errorMessage = _get(error, 'message', null);
  }
  let message = I18n.t(`serverErrors.${errorMessage}`);

  if (_isEmpty(message)) {
    if (errorMessage) {
      message = errorMessage;
    } else {
      message = I18n.t('serverErrors.default');
    }
  }
  const details = _get(error, 'errors.details', []);
  if (details.length > 0) {
    const detailsToShow = details
      .map((detail) => Object.values(detail).join(' '))
      .join(', ');
    message += ` (${detailsToShow})`;
  }
  dispatch(openModalWindow({message}, MODAL_WINDOW_NOTIFY_TYPE.ERROR));
}
