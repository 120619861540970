import React from 'react';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';

import EntityCreatingCard from '../EntityCreatingCard/EntityCreatingCard';
import PersonasEditForm from '../../containers/PersonasEditForm/PersonasEditForm';

function PersonaCreating({handleClickButtonCancel,
  handleClickButtonSave,
  isValidPersonaInfo,
  domains,
  tenants,
  getPolicies}) {
  return (
    <EntityCreatingCard
      title={I18n.t('users.usersConfigurer.persona.newPersona')}
      handleClickButtonCancel={handleClickButtonCancel}
      handleClickButtonSave={handleClickButtonSave}
      isValidEntity={isValidPersonaInfo}
      customCardContentClass="card__content_clientProfile"
      content={(
        <PersonasEditForm
          getPolicies={getPolicies}
          domains={domains}
          tenants={tenants}
          isEditMode={true}
        />
      )}
    />
  );
}

PersonaCreating.propTypes = {
  getPolicies: PropTypes.func.isRequired,
  domains: PropTypes.array.isRequired,
  tenants: PropTypes.array.isRequired,
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  isValidPersonaInfo: PropTypes.bool.isRequired,
};

export default PersonaCreating;
