import React from 'react';
import {I18n} from 'react-redux-i18n';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

import {CustomButton} from '../../material-dashboard-pro-react/components';

import {getTenantByDomain} from './action';

/**
 * App to register Entra app
 * @param appId - ID of the app from Entra
 * @param redirectUrl - URL where response with tenantId and state will be sent.
 * @param domain - tenant domain (optional), required for multi-tenant accounts.
 * URL needs to also be added to the app in entra
 * @param state - optional. Any param. Will be returned as param to `redirectUrl`
 */
export function RegisterEntraAppButton({appId,
  domain,
  state,
  redirectUrl,
  disabled = false}) {
  const dispatch = useDispatch();

  const onButtonClick = () => {
    dispatch(getTenantByDomain(appId, domain, state, redirectUrl));
  };

  return (
    <CustomButton
      color="primary"
      customClasses="uppercase"
      disabled={disabled}
      onClick={onButtonClick}
    >
      {I18n.t('users.usersConfigurer.persona.entraId.addTenant')}
    </CustomButton>
  );
}

RegisterEntraAppButton.propTypes = {
  appId: PropTypes.string.isRequired,
  domain: PropTypes.string,
  state: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

RegisterEntraAppButton.defaultProps = {
  domain: null,
  disabled: false,
};
