import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';
import _isUndefined from 'lodash/isUndefined';

import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import {
  ButtonSave,
  ButtonCancel,
  ButtonEditEntity,
  ButtonDeleteEntity,
  ButtonBack,
  DeleteEntityModalWindow,
  NotifyModalWindow,
  RenderOrEmpty,
} from '../index';

import {RightAvailability, RightAvailabilityOrWarn} from '../../containers/index';

import {ACCESS_RIGHTS, ORGANIZATION_OPERATOR_PERMISSIONS, USER_ROLES} from '../../constants';

import style from '../../styles/components/cards';

class EntityEditingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDeleteModalWindow: false,
      openDenyEditModalWindow: false,
      openDenyDeleteModalWindow: false,
    };
  }

  get title() {
    const {
      classes,
      isEditMode,
      isTherePathBack,
      title,
      handleClickButtonBack,
    } = this.props;
    if (isTherePathBack) {
      return (
        <>
          <ButtonBack
            customClasses="cardActions__button_back"
            disabled={isEditMode}
            onClick={handleClickButtonBack}
          />
          <h2 className={classes.cardTitle__entityName}>
            {title}
          </h2>
        </>
      );
    }
    return title;
  }

  get actions() {
    const {
      additionalActions,
      isThereEditBlock,
      showOnlyAdditionalActions,
      isEditMode,
      entityType,
      handleClickButtonEdit,
      handleClickButtonSave,
      handleClickButtonCancel,
      isValidEntity,
      showOnAccessDenyButtonEdit,
      accessRights,

    } = this.props;
    const {openDenyEditModalWindow} = this.state;

    if (!isThereEditBlock) return null;
    if (showOnlyAdditionalActions && additionalActions) {
      return additionalActions;
    }
    if (isEditMode) {
      return (
        <>
          <ButtonSave
            customClasses="cardActions__button"
            disabled={!isValidEntity}
            onClick={handleClickButtonSave}
          />
          <ButtonCancel
            customClasses={classNames(
              'cardActions__button',
              'cardActions__button_last',
              'cardActions__button_cancel',
            )}
            onClick={handleClickButtonCancel}
          />
        </>
      );
    } if (!showOnAccessDenyButtonEdit) {
      return (
        <div className={this.props.classes.card__actionBlock}>
          {additionalActions}
          <RightAvailability accessRights={accessRights.buttonEdit}>
            <ButtonEditEntity
              customClasses="cardActions__button"
              text={this.props.editButtonText}
              entityType={entityType.toUpperCase()}
              onClick={handleClickButtonEdit}
            />
          </RightAvailability>
        </div>
      );
    }

    return (
      <div className={this.props.classes.card__actionBlock}>
        {additionalActions}
        <RightAvailabilityOrWarn
          accessRights={accessRights.buttonEdit}
          onClickDeny={this.handleForbiddenEditButton}
          limitDenyFor={[
            {
              role: USER_ROLES.ORGANIZATION_OPERATOR,
              permission: ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER,
            },
          ]}
        >
          <ButtonEditEntity
            customClasses="cardActions__button"
            text={this.props.editButtonText}
            entityType={entityType.toUpperCase()}
            onClick={handleClickButtonEdit}
          />
        </RightAvailabilityOrWarn>
        <RenderOrEmpty isShow={openDenyEditModalWindow}>
          <NotifyModalWindow
            text={I18n.t(
              'modalWindow.forbidAction',
              {
                entityType: entityType,
                entityAction: 'edit',
              },
            )}
            close={this.handleClickDenyOK}
          />
        </RenderOrEmpty>
      </div>
    );
  }

  handleClickButtonDelete = () => {
    this.setState({
      openDeleteModalWindow: true,
    });
  };

  handleCancelDelete = () => {
    this.setState({
      openDeleteModalWindow: false,
    });
  };

  handleConfirmDelete = () => {
    this.setState({
      openDeleteModalWindow: false,
    });
    this.props.handleClickButtonDelete();
  };

  handleForbiddenEditButton = () => {
    this.setState({
      openDenyEditModalWindow: true,
    });
  };

  handleForbiddenDeleteButton = () => {
    this.setState({
      openDenyDeleteModalWindow: true,
    });
  };

  handleClickDenyOK = () => {
    this.setState({
      openDenyEditModalWindow: false,
      openDenyDeleteModalWindow: false,
    });
  };

  render() {
    const {
      classes,
      isEditMode,
      content,
      entityType,
      isThereDeleteBlock,
      customCardClass,
      customCardContentClass,
      isFullscreen,
      showOnAccessDenyButtonDelete,
      accessRights,
      deleteModalWindowText,
    } = this.props;

    const {openDeleteModalWindow, openDenyDeleteModalWindow} = this.state;

    const headerClasses = {
      root: classes.card__header,
      title: classNames(
        classes.card__title,
        {
          [classes.card__title_edit]: isEditMode,
          [classes.card__title_view]: !isEditMode,
        },
      ),
      action: classes.card__actions_edit,
      subheader: classes.card__subheader,
    };
    if (!showOnAccessDenyButtonDelete) {
      return (
        <Card
          className={classNames(
            classes.card,
            {
              [classes.card_fullScreen]: isFullscreen,
              [classes[customCardClass]]: !_isUndefined(customCardClass),
            },
          )}
        >
          <CardHeader
            classes={headerClasses}
            title={this.title}
            action={this.actions}
            subheader={(
              <RenderOrEmpty isShow={isThereDeleteBlock}>
                <RightAvailability accessRights={accessRights.buttonDelete}>
                  <ButtonDeleteEntity
                    customClasses="cardActions__button_delete"
                    text={this.props.deleteButtonText}
                    disabled={isEditMode}
                    entityType={entityType.toUpperCase()}
                    onClick={this.handleClickButtonDelete}
                  />
                </RightAvailability>
                <RenderOrEmpty isShow={openDeleteModalWindow}>
                  <DeleteEntityModalWindow
                    text={deleteModalWindowText || I18n.t(
                      'modalWindow.delete',
                      {
                        entityType: entityType,
                      },
                    )}
                    cancel={this.handleCancelDelete}
                    confirm={this.handleConfirmDelete}
                  />
                </RenderOrEmpty>
              </RenderOrEmpty>
            )}
          />
          <CardContent
            className={classNames(
              classes.card__content,
              {
                [classes[customCardContentClass]]: !_isUndefined(customCardContentClass),
              },
            )}
          >
            {content}
          </CardContent>
        </Card>
      );
    }

    return (
      <Card
        className={classNames(
          classes.card,
          {
            [classes.card_fullScreen]: isFullscreen,
            [classes[customCardClass]]: !_isUndefined(customCardClass),
          },
        )}
      >
        <CardHeader
          classes={headerClasses}
          title={this.title}
          action={this.actions}
          subheader={(
            <RenderOrEmpty isShow={isThereDeleteBlock}>
              <RightAvailabilityOrWarn
                accessRights={accessRights.buttonDelete}
                onClickDeny={this.handleForbiddenDeleteButton}
                limitDenyFor={[
                  {
                    role: USER_ROLES.ORGANIZATION_OPERATOR,
                    permission: ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER,
                  },
                ]}
              >
                <ButtonDeleteEntity
                  customClasses="cardActions__button_delete"
                  text={this.props.deleteButtonText}
                  disabled={isEditMode}
                  entityType={entityType.toUpperCase()}
                  onClick={this.handleClickButtonDelete}
                />
              </RightAvailabilityOrWarn>
              <RenderOrEmpty isShow={openDeleteModalWindow}>
                <DeleteEntityModalWindow
                  text={deleteModalWindowText || I18n.t(
                    'modalWindow.delete',
                    {
                      entityType: entityType,
                    },
                  )}
                  cancel={this.handleCancelDelete}
                  confirm={this.handleConfirmDelete}
                />
              </RenderOrEmpty>
              <RenderOrEmpty isShow={openDenyDeleteModalWindow}>
                <NotifyModalWindow
                  text={I18n.t(
                    'modalWindow.forbidAction',
                    {
                      entityType: entityType,
                      entityAction: 'delete',
                    },
                  )}
                  close={this.handleClickDenyOK}
                />
              </RenderOrEmpty>
            </RenderOrEmpty>
          )}
        />
        <CardContent
          className={classNames(
            classes.card__content,
            {
              [classes[customCardContentClass]]: !_isUndefined(customCardContentClass),
            },
          )}
        >
          {content}
        </CardContent>
      </Card>
    );
  }
}

EntityEditingCard.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.node,
  customCardClass: PropTypes.string,
  customCardContentClass: PropTypes.string,
  deleteModalWindowText: PropTypes.string,
  deleteButtonText: PropTypes.string,
  editButtonText: PropTypes.string,
  entityType: PropTypes.string,
  handleClickButtonBack: PropTypes.func,
  handleClickButtonCancel: PropTypes.func,
  handleClickButtonDelete: PropTypes.func,
  handleClickButtonEdit: PropTypes.func,
  handleClickButtonSave: PropTypes.func,
  isEditMode: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  isThereDeleteBlock: PropTypes.bool,
  isThereEditBlock: PropTypes.bool,
  isTherePathBack: PropTypes.bool,
  isValidEntity: PropTypes.bool,
  showOnlyAdditionalActions: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showOnAccessDenyButtonEdit: PropTypes.bool,
  showOnAccessDenyButtonDelete: PropTypes.bool,
  accessRights: PropTypes.object,
  additionalActions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

EntityEditingCard.defaultProps = {
  title: '',
  content: '',
  isEditMode: false,
  entityType: '',
  isThereEditBlock: false,
  isThereDeleteBlock: false,
  isTherePathBack: false,
  isValidEntity: true,
  isFullscreen: true,
  showOnlyAdditionalActions: false,
  handleClickButtonEdit: () => {},
  handleClickButtonSave: () => {},
  handleClickButtonCancel: () => {},
  handleClickButtonDelete: () => {},
  handleClickButtonBack: () => {},
  customCardClass: undefined,
  customCardContentClass: undefined,
  deleteModalWindowText: null,
  deleteButtonText: null,
  editButtonText: null,
  accessRights: {buttonEdit: [ACCESS_RIGHTS.EDIT], buttonDelete: [ACCESS_RIGHTS.DELETE]},
  showOnAccessDenyButtonEdit: false,
  showOnAccessDenyButtonDelete: false,
  additionalActions: null,
};

export default withStyles(style)(EntityEditingCard);
