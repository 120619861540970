import {useDispatch, useSelector, useStore} from 'react-redux';
import {configureStore} from '@reduxjs/toolkit';

import rootReducer from '../reducers/root-reducer';
import reduxInitialState from '../reducers/redux-initial-state';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];

// Temporarily solution to see types of store
export type AppStoreType = typeof reduxInitialState;

/**
 * Redux doesn't seen to properly support with types which causes errors
 * Redux doc recommend to use Redux Toolkit,
 * so to use typed selector we need to update or find some other way
 * @returns useDispatch from redux without any changes
 */
export const useAppDispatch: () => AppDispatch = useDispatch;
/**
 * Redux doesn't seen to properly support with types which causes errors
 * Redux doc recommend to use Redux Toolkit,
 * so to use typed selector we need to update or find some other way
 * @returns useSelector from redux without any changes
 */
export const useAppSelector = useSelector;
export const useAppStore: () => AppStore = useStore;
