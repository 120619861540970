import React from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _isUndefined from 'lodash/isUndefined';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import CheckboxMUI from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {RenderOrEmpty} from '../index';

import style from './style';

function Checkbox(props) {
  const {
    classes,
    checkboxClassName,
    labelFormClassName,
    labelClassName,
    disabled,
    label,
    // eslint-disable-next-line no-shadow
    name,
    inputProps,
    isChecked,
    onChange,
  } = props;
  const checkboxClasses = {
    root: classNames(
      classes.checkbox,
      {
        [classes[checkboxClassName]]: !_isUndefined(checkboxClassName),
        [classes.checkbox_checked]: isChecked,
        [classes.checkbox_unchecked]: !isChecked,
        [classes.checkbox_disabled]: disabled,
      },
    ),
    checked: '',
  };
  const labelFormClasses = {
    root: classNames(
      classes.formControl,
      {
        [classes[labelFormClassName]]: !_isUndefined(labelFormClassName),
      },
    ),
    label: classNames(
      classes.checkbox__label,
      {
        [classes[labelClassName]]: !_isUndefined(labelClassName),
      },
    ),
  };
  const inputProperties = {
    ...inputProps,
    name: name,
  };
  const CheckboxComponent = (
    <CheckboxMUI
    classes={checkboxClasses}
    checked={isChecked || _isEqual(isChecked, 'true')}
    disabled={disabled}
    inputProps={inputProperties}
    onChange={onChange}
    />
  );
  return (
    <>
      <RenderOrEmpty isShow={!_isUndefined(label)}>
        <FormControlLabel
          control={CheckboxComponent}
          label={label}
          classes={labelFormClasses}
        />
      </RenderOrEmpty>
      <RenderOrEmpty isShow={_isUndefined(label)}>
        {CheckboxComponent}
      </RenderOrEmpty>
    </>
  );
}

Checkbox.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  isChecked: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  inputProps: PropTypes.object,
  checkboxClassName: PropTypes.string,
  labelFormClassName: PropTypes.string,
  labelClassName: PropTypes.string,
};

Checkbox.defaultProps = {
  onChange: () => {},
  isChecked: false,
  disabled: false,
  inputProps: {},
  name: '',
  label: undefined,
  checkboxClassName: undefined,
  labelFormClassName: undefined,
  labelClassName: undefined,
};

export default withStyles(style)(Checkbox);
