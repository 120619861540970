import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuid} from 'uuid';
import classNames from 'classnames';
import _isEqual from 'lodash/isEqual';

import {withStyles} from '@material-ui/core/styles';

import {RightAvailability} from '../../containers';

import style from './style';
import RenderOrEmpty from '../RenderOrEmpty/RenderOrEmpty';

function TabsPanel(props) {
  const {
    activeTabIndex,
    classes,
    disabled,
    handleClickTab,
    tabs,
  } = props;

  return (
    <div
      className={classNames(
        classes.panel,
        {
          [classes.panel_disabled]: disabled,
        },
      )}
    >
      {tabs.map((item, index) => (
        <RenderOrEmpty
          key={uuid()}
          isShow={!item.hidden}
        >
          <RightAvailability
            accessRights={item.accessRights !== undefined ? item.accessRights : []}
          >
            <div
              onClick={() => handleClickTab(index)}
              onKeyPress={() => {}}
              className={classNames(
                classes.panel__tab,
                {
                  [classes.panel__tab_active]: _isEqual(index, activeTabIndex),
                },
              )}
            >
              {item.text}
            </div>
          </RightAvailability>
        </RenderOrEmpty>
      ))}
    </div>
  );
}

TabsPanel.propTypes = {
  activeTabIndex: PropTypes.number,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  handleClickTab: PropTypes.func.isRequired,
  tabs: PropTypes.array,
};

TabsPanel.defaultProps = {
  activeTabIndex: 0,
  disabled: false,
  tabs: [],
};

export default withStyles(style)(TabsPanel);
