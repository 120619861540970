import {I18n} from 'react-redux-i18n';
import {
  makeUrl,
  post,
  get,
} from '../../utils/fetcher';

import {
  convertServerPersona,
  getPersonaToCreate,
  getPersonaToUpdate,
} from './service';

import {
  METHODS,
  MODAL_WINDOW_NOTIFY_TYPE,
  STATES_ENTITY,
} from '../../constants';
import {openModalWindow} from '../../oem/layouts/Operator/action';
import {updateBaseSettingAndUnbind} from '../PersonasEditForm/action';

export const types = {
  CHANGE_EDIT_MODE_AND_PERSONA_STATE: 'CHANGE_EDIT_MODE_AND_PERSONA_STATE',
  SAVE_EDITED_PERSONA: 'SAVE_EDITED_PERSONA',
  SET_DEFAULT_PERSONAS: 'SET_DEFAULT_PERSONAS',
  SET_PERSONAS: 'SET_PERSONAS',
  SET_DOMAINS: 'SET_DOMAINS',
  SET_AD_OBSERVED_GROUPS: 'SET_AD_OBSERVED_GROUPS',
  SET_PERSONAS_TABLE_DATA: 'SET_PERSONAS_TABLE_DATA',
  SET_PERSONA_POLICIES: 'SET_PERSONA_POLICIES',
  SET_SELECTED_PERSONA: 'SET_SELECTED_PERSONA',
  SET_SELECTED_PERSONA_AD_DOMAIN_ID: 'SET_SELECTED_PERSONA_AD_DOMAIN_ID',
  SET_SELECTED_PERSONA_INDEX: 'SET_SELECTED_PERSONA_INDEX',
  SET_UPDATED_PERSONA: 'SET_UPDATED_PERSONA',
  RESET_PERSONAS: 'RESET_PERSONAS',
  RESET_SELECTED_PERSONA_INDEX: 'RESET_SELECTED_PERSONA_INDEX',
  RESET_PERSONA_DATA: 'RESET_PERSONA_DATA',

  SET_TENANTS: 'SET_TENANTS',
  SET_TENANT_ADDED: 'SET_TENANT_ADDED',
};

export function getDomains() {
  const url = makeUrl(METHODS.GET_AD_DOMAINS, {unbindOnly: true});
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_DOMAINS,
          data,
        });
        return data;
      }
      return [];
    });
}

export function getPersonas() {
  const url = makeUrl(METHODS.GET_PERSONAS);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_PERSONAS,
          data,
        });
      }
    });
}

export function getPersonaByIdWithDomainGroups(personaId) {
  const url = makeUrl(METHODS.GET_PERSONA_AND_DOMAIN_GROUPS, {id: personaId});
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = convertServerPersona(response.data);
        dispatch({
          type: types.SET_SELECTED_PERSONA,
          data,
        });
      }
    });
}

export function createPersona(newData) {
  const url = makeUrl(METHODS.CREATE_PERSONA);
  const data = getPersonaToCreate(newData);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(changeEditModeAndPersonaState(false, STATES_ENTITY.NONE));
        dispatch(updatePersonaPageData());
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Unable to create persona:', error);
    });
}

export function updatePersona(newData) {
  const url = makeUrl(METHODS.UPDATE_PERSONA);
  const data = getPersonaToUpdate(newData);
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch({
          type: types.SET_UPDATED_PERSONA,
          data: convertServerPersona(response.data),
        });
        dispatch(getPersonas())
          .then(() => dispatch(getPersonasTableData()));
        dispatch(getDomains());
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Unable to edit persona:', error);
    });
}

export function deletePersona(personaId) {
  const url = makeUrl(METHODS.DELETE_PERSONA);
  const data = {
    id: personaId,
  };
  return (dispatch) => post(url, data, dispatch)
    .then((response) => {
      if (response && response.success) {
        dispatch(updatePersonaPageData());
        return true;
      }
      return false;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Unable to delete persona:', error);
    });
}

export function getPersonaPolicies(personaId) {
  const url = makeUrl(METHODS.GET_PERSONA_POLICIES, {personaId});
  return (dispatch) =>
    get(url, dispatch)
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_PERSONA_POLICIES,
            data,
          });
        }
      });
}

export function getDataToShowPersonaDetails(personaId, personaIndex) {
  return (dispatch) => {
    dispatch(getPersonaByIdWithDomainGroups(personaId));
    dispatch(setSelectedPersonaIndex(personaIndex));
  };
}

export function getPersonaDetailsAfterAddingTenant(personaId, personaIndex, baseSettings) {
  return async(dispatch) => {
    dispatch(setSelectedPersonaIndex(personaIndex));
    await dispatch(getPersonaByIdWithDomainGroups(personaId));
    dispatch(updateBaseSettingAndUnbind(baseSettings));
  };
}

export function resetPersonas() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_PERSONAS,
    });
  };
}

export function changeEditModeAndPersonaState(isEditMode, personaState) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_EDIT_MODE_AND_PERSONA_STATE,
      isEditMode,
      personaState,
    });
  };
}

export function getPersonasTableData() {
  return (dispatch) => {
    dispatch({
      type: types.SET_PERSONAS_TABLE_DATA,
    });
  };
}

export function setSelectedPersonaIndex(index) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_PERSONA_INDEX,
      selectedPersonaIndex: index,
    });
  };
}

export function resetSelectedPersonaIndex() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_PERSONA_INDEX,
    });
  };
}

export function updatePersonaPageData() {
  return (dispatch) => {
    dispatch(resetSelectedPersonaIndex());
    dispatch(getPersonas());
    dispatch(getDomains());
  };
}

export function resetPersonaData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_PERSONA_DATA,
    });
  };
}

export function setPersonaObservedGroups(groups) {
  return (dispatch) => {
    dispatch({
      type: types.SET_AD_OBSERVED_GROUPS,
      groups,
    });
  };
}

export function addTenantToAccount(tenantId, accountId) {
  const queryParams = {
    tenantId, accountId,
  };
  const url = makeUrl(METHODS.ADD_ENTRA_TENANT, queryParams);
  return (dispatch) => post(url, {}, dispatch)
    .then((response) => dispatch({
      type: types.SET_TENANT_ADDED,
      success: response && response.success,
      message: (response && response.errorMessage) ?? '',
    }))
    .catch((error) => {
      dispatch({
        type: types.SET_TENANT_ADDED,
        success: error && error.success,
        message: (error && error.errorMessage) ?? '',
      });
      // eslint-disable-next-line no-console
      console.error('Unable to save tenant:', error);
    });
}

export function getTenants() {
  const url = makeUrl(METHODS.GET_ENTRA_TENANTS, {unbindOnly: true});
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        const data = response.data;
        dispatch({
          type: types.SET_TENANTS,
          data,
        });
        return data;
      }
      return [];
    });
}

export function syncEntraGroups(entraTenantId) {
  const url = makeUrl(METHODS.SYNC_ENTRA_GROUPS, {entraTenantId});
  return (dispatch) => post(url, {}, dispatch, false)
    .then((response) => {
      if (response && response.success) {
        dispatch(openModalWindow(
          I18n.t('users.usersConfigurer.persona.entraId.syncStarted'),
          MODAL_WINDOW_NOTIFY_TYPE.INFO,
        ));
      }
    })
    .catch((e) => {
      dispatch(openModalWindow(
        I18n.t(`serverErrors.${e.errorMessage}`),
        MODAL_WINDOW_NOTIFY_TYPE.INFO,
      ));
    });
}
