import _isNil from 'lodash/isNil';
import {timestampMilliToUserDate} from '../../utils/dateTime';

export const getUsersTableData = (data, roamingLabel) => data.map((item) => ({
  ...item,
  username: item.name,
  group: item.topGroup,
  lastSeenAt: timestampMilliToUserDate(item.lastSeenAt),
  site: (_isNil(item.site)) ? roamingLabel : item.site,
}));

export const convertUserInfo = (user, roamingLabel) => ({
  ...user,
  lastSeenAt: timestampMilliToUserDate(user.lastSeenAt),
  site: (_isNil(user.site)) ? roamingLabel : user.site,
});
