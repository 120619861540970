import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';

import EntityEditingCard from '../EntityEditingCard/EntityEditingCard';
import PersonasEditForm from '../../containers/PersonasEditForm/PersonasEditForm';
import {
  updateDirectoryServices,
  updateBaseSetting,
  updateEntraId,
} from '../../containers/PersonasEditForm/action';
import {ACCESS_RIGHTS} from '../../constants';
import {
  getPersonaBaseSettings,
  getPersonaDirectoryServiceSettings,
  getPersonaEntraIdSettings,
} from './utils';

const rightAvailabilityMap = {
  buttonEdit: [ACCESS_RIGHTS.PERSONA_EDIT],
  buttonDelete: [ACCESS_RIGHTS.PERSONA_DELETE],
};

function PersonaDetails({
  persona,
  getPolicies,
  domains,
  tenants,
  isEditMode,
  handleClickButtonBack,
  handleClickButtonEdit,
  handleClickButtonDelete,
  handleClickButtonCancelEdit,
  handleClickButtonSave,
  isValidPersonaInfo,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateBaseSetting(getPersonaBaseSettings(persona)));
    dispatch(updateDirectoryServices(getPersonaDirectoryServiceSettings(persona)));
    dispatch(updateEntraId(getPersonaEntraIdSettings(persona)));
  }, [persona]);

  useEffect(() => {
    if (!isEditMode) {
      dispatch(updateBaseSetting(getPersonaBaseSettings(persona)));
      dispatch(updateDirectoryServices(getPersonaDirectoryServiceSettings(persona)));
      dispatch(updateEntraId(getPersonaEntraIdSettings(persona)));
    }
  }, [isEditMode]);

  return (
    <EntityEditingCard
      title={persona.name}
      entityType={I18n.t('entitiesTypes.persona')}
      isThereDeleteBlock={true}
      isThereEditBlock={true}
      isTherePathBack={true}
      isEditMode={isEditMode}
      isValidEntity={isValidPersonaInfo}
      handleClickButtonBack={handleClickButtonBack}
      handleClickButtonDelete={handleClickButtonDelete}
      handleClickButtonEdit={handleClickButtonEdit}
      handleClickButtonCancel={handleClickButtonCancelEdit}
      handleClickButtonSave={handleClickButtonSave}
      customCardContentClass="card__content_persona"
      showOnAccessDenyButtonDelete={true}
      isFullscreen={false}
      accessRights={rightAvailabilityMap}
      content={(
        <PersonasEditForm
          getPolicies={getPolicies}
          domains={domains}
          tenants={tenants}
          isEditMode={isEditMode}
        />
      )}
    />
  );
}

PersonaDetails.propTypes = {
  persona: PropTypes.object.isRequired,
  getPolicies: PropTypes.func.isRequired,
  domains: PropTypes.array.isRequired,
  tenants: PropTypes.array.isRequired,
  handleClickButtonBack: PropTypes.func.isRequired,
  handleClickButtonCancelEdit: PropTypes.func.isRequired,
  handleClickButtonDelete: PropTypes.func.isRequired,
  handleClickButtonEdit: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isValidPersonaInfo: PropTypes.bool.isRequired,
};

export default PersonaDetails;
