import {METHODS} from '../../constants';
import {types, UserServices} from './constants';
import {get, makeUrl, post} from '../../utils/fetcher';
import {convertUserInfo, getUsersTableData} from './utils';

export const getADUserInfo = (userId, roamingLabel) => {
  const url = makeUrl(METHODS.GET_AD_USER_INFO, {userId});
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_SELECTED_USER,
          data: convertUserInfo(response.data, roamingLabel),
        });
      }
      return response?.data;
    });
};

export const getEntraUserInfo = (userId, roamingLabel) => {
  const url = makeUrl(METHODS.GET_ENTRA_USER_INFO, {userId});
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_SELECTED_USER,
          data: convertUserInfo(response.data, roamingLabel),
        });
      }
      return response?.data;
    });
};

/**
 * @param {usersList} usersList - array of users.
 * Each user should have `id` field and `type` field with user service type
 */
export function forgetUsersByIdList(usersList) {
  const url = makeUrl(METHODS.FORGET_USERS_BY_ID_LIST);
  const data = usersList;
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => response && response.success)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Unable to forget users list:', error);
      });
}

/**
 * Delete a single AD/Entra user
 * @param userId - id of the user to delete
 * @param userService service of the user from `UserServices`
 */
export function forgetUserById(userId, userService) {
  const url = makeUrl(METHODS.FORGET_USER_BY_ID);
  const data = {
    id: userId,
    type: userService,
  };
  return (dispatch) =>
    post(url, data, dispatch)
      .then((response) => response && response.success)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Unable to forget user:', error);
      });
}

export const setSelectedUserIndex = (index) =>
  (dispatch) => dispatch({
    type: types.SET_SELECTED_USER_INDEX,
    index,
  });

export const resetSelectedUserIndex = () =>
  (dispatch) => {
    dispatch({
      type: types.RESET_SELECTED_USER_INDEX,
    });
  };

export const getDataToShowUserDetails = (userId, userIndex, roamingLabel, userService) =>
  (dispatch) => {
    if (userService === UserServices.ACTIVE_DIRECTORY) {
      dispatch(getADUserInfo(userId, roamingLabel));
    } else {
      dispatch(getEntraUserInfo(userId, roamingLabel));
    }
    dispatch(setSelectedUserIndex(userIndex));
  };

export const loadGroups = (adDomainId = null) => {
  const url = makeUrl(METHODS.GET_USER_GROUP_NAMES, {adDomainId});
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_GROUP_NAMES,
          groups: response.data,
        });
      }
      return response?.data;
    });
};

export const setSearchOption = (optionName, value) => (dispatch) => dispatch({
  type: types.SET_SEARCH_OPTION,
  optionName,
  value,
});

export const getUsers = (pageOptions, actionAfterLoad, roamingLabel) => {
  const url = makeUrl(METHODS.GET_USERS, pageOptions);
  return (dispatch) => get(url, dispatch)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: actionAfterLoad,
          data: getUsersTableData(response.data, roamingLabel),
        });
      }
    });
};

export const loadUsers = (pageOptions, roamingLabel) =>
  getUsers(pageOptions, types.SET_USERS, roamingLabel);

export const loadMoreUsers = (pageOptions, roamingLabel) =>
  getUsers(pageOptions, types.ADD_USERS, roamingLabel);

export const resetUsersManagerData = () => (dispatch) => dispatch({
  type: types.RESET_USERS_MANAGER_DATA,
});

export const resetFilters = () => (dispatch) => dispatch({
  type: types.RESET_FILTERS,
});
