import {get, makeUrl} from '../../utils/fetcher';
import {METHODS} from '../../constants';
import {types} from './constants';
import {getSessionData} from './utils';
import {AppDispatch} from '../../utils/store';
import initState from '../../reducers/redux-initial-state';

export const loadAdUserSessions = (userId: string, period: string, widgetKey: string) => {
  const url = makeUrl(METHODS.GET_AD_USER_SESSIONS, {adUserId: userId, period});
  return (dispatch: AppDispatch) => get(url, dispatch, true, widgetKey)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_USER_SESSIONS,
          data: getSessionData(response.data),
        });
      } else {
        dispatch({
          type: types.SET_USER_SESSIONS,
          data: initState.userSessionsReducer.sessions,
        });
      }
    });
};

export const loadEntraUserSessions = (userId: string, period: string, widgetKey: string) => {
  const url = makeUrl(METHODS.GET_ENTRA_USER_SESSIONS, {entraUserId: userId, period});
  return (dispatch: AppDispatch) => get(url, dispatch, true, widgetKey)
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: types.SET_USER_SESSIONS,
          data: getSessionData(response.data),
        });
      } else {
        dispatch({
          type: types.SET_USER_SESSIONS,
          data: initState.userSessionsReducer.sessions,
        });
      }
    });
};
