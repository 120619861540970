import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import {withStyles} from '@material-ui/core/styles';

import {ButtonCancel,
  InfiniteListWithViewModelsCard,
  RenderDivOrEmpty,
  StatusIndicator,
  UserServiceIcon} from '../index';
import {CustomButton} from '../../material-dashboard-pro-react/components';
import {formatPolicy} from '../../utils/policy';

import {FULL_WIDTH_MODE_TABLE, USER_ROLES} from '../../constants';
import {RoleAvailability} from '../../containers';

import style from './style';

const multiSelectAvailabilityMap = {
  [USER_ROLES.VIEWER]: false,
  [USER_ROLES.SERVICE_DESK]: false,
  [USER_ROLES.PROJECT_VIEWER]: false,
};

class UsersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {isMultiSelectShown: false};

    this.statusIndicatorCellRenderer = (rowInfo) => (
      <StatusIndicator status={`DEVICE_AGENT_${rowInfo.value}`} />
    );
    this.policyRenderer = (rowInfo) =>
      formatPolicy(rowInfo.original.policy, rowInfo.original.policyType, I18n.t);
    this.groupsRenderer = (rowInfo) => {
      const groups = rowInfo.original.groups;
      const topGroupIdx = rowInfo.original.topGroupIdx;
      if (_isNil(groups) || _isEmpty(groups)) {
        return '-';
      }
      if (groups.length === 1) {
        return groups[0].name;
      }
      const groupToShow = (!_isNil(topGroupIdx)) ? groups[topGroupIdx] : groups[0];
      return `${groupToShow.name} (+${groups.length - 1})`;
    };
    this.userServiceIconRenderer =
      (rowInfo) => <UserServiceIcon service={rowInfo.original.service} />;
  }

  get fullScreenColumns() {
    return [
      {
        Header: I18n.t('users.usersManager.table.headers.service'),
        Cell: this.userServiceIconRenderer,
        minWidth: 72,
        filterable: false,
        sortable: false,
        isShow: true,
      },
      {
        Header: I18n.t('users.usersManager.table.headers.username'),
        accessor: 'username',
        minWidth: 150,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('users.usersManager.table.headers.domain'),
        accessor: 'domain',
        minWidth: 150,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('users.usersManager.table.headers.tenant'),
        accessor: 'entraTenant',
        minWidth: 150,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      // {
      //   Header: I18n.t('users.usersManager.table.headers.organization'),
      //   accessor: 'organization',
      //   minWidth: 100,
      //   filterable: false,
      //   sortable: true,
      //   isShow: true,
      // },
      // {
      //   Header: I18n.t('users.usersManager.table.headers.persona'),
      //   accessor: 'persona',
      //   minWidth: 100,
      //   filterable: false,
      //   sortable: true,
      //   isShow: true,
      // },
      {
        Header: I18n.t('users.usersManager.table.headers.userStatus'),
        accessor: 'userStatus',
        Cell: this.statusIndicatorCellRenderer,
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('users.usersManager.table.headers.lastSeen'),
        accessor: 'lastSeenAt',
        minWidth: 200,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('users.usersManager.table.headers.lastDevice'),
        accessor: 'lastDevice',
        minWidth: 200,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('users.usersManager.table.headers.site'),
        accessor: 'site',
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
      {
        Header: I18n.t('users.usersManager.table.headers.groups'),
        accessor: 'group',
        Cell: this.groupsRenderer,
        minWidth: 100,
        filterable: false,
        sortable: false,
        isShow: true,
      },
      {
        Header: I18n.t('users.usersManager.table.headers.policy'),
        accessor: 'policy',
        Cell: this.policyRenderer,
        minWidth: 100,
        filterable: false,
        sortable: false,
        isShow: true,
      },
    ];
  }

  get compressedColumn() {
    return [
      {
        Header: I18n.t('users.usersManager.table.headers.user'),
        accessor: 'name',
        minWidth: 100,
        filterable: false,
        sortable: true,
        isShow: true,
      },
    ];
  }

  onMultiSelectButtonClick = () => {
    this.setState({
      isMultiSelectShown: true,
    });
  };

  onMultiSelectCancel = () => {
    this.setState({
      isMultiSelectShown: false,
    });
  };

  forgetUsersByIdList = () => {
    this.props.handleForgetUsersByIdList();
    this.setState({
      isMultiSelectShown: false,
    });
  };

  headerActions = () => {
    const {isMultiSelectShown} = this.state;
    const {
      classes,
      mode,
    } = this.props;

    if (mode !== FULL_WIDTH_MODE_TABLE) {
      return [];
    }

    return [
      <div key="users-fullWidthMode" className={classes.usersTableCard__additionalHeaderActions}>
        <RoleAvailability availability={multiSelectAvailabilityMap} alternativeChild={null}>
          <RenderDivOrEmpty isShow={!isMultiSelectShown}>
            <CustomButton
              color="secondary"
              fullWidth={true}
              onClick={this.onMultiSelectButtonClick}
            >
              {I18n.t('users.usersManager.table.multiselect')}
            </CustomButton>
          </RenderDivOrEmpty>
        </RoleAvailability>
        <RenderDivOrEmpty isShow={isMultiSelectShown} className={classes.usersTableCard__multiselectOptions}>
          <CustomButton
            color="secondary"
            fullWidth={true}
            onClick={this.forgetUsersByIdList}
          >
            {I18n.t('users.usersManager.table.forget')}
          </CustomButton>
          <ButtonCancel onClick={this.onMultiSelectCancel} />
        </RenderDivOrEmpty>
      </div>,
    ];
  };

  render() {
    const {isMultiSelectShown} = this.state;
    const {
      classes,
      customClassNames,
      tableData,
      dataWithSelect,
      selectAll,
      mode,
      selectedUserIndex,
      minRows,
      isEditMode,
      loadData,
      handleClickRow,
      handleChangeDataWithSelect,
      handleChangeSelectAll,
    } = this.props;

    const tableClassNameObj = {
      tbody: classNames(
        classes.usersTable__tbodyInfinite,
        {
          [classes.tbodyInfinite_noItems]: tableData.length === 0,
          [classes[customClassNames.tbody]]: !_isNil(customClassNames.tbody),
        },
      ),
      header: {
        root: classes.usersTableCard__header,
        title: classes.usersTableCard__title,
        action: classes.usersTableCard__action,
      },
      card: classes.usersTableCard,
      card__content: classes.usersTableCard__content,
    };

    return (
      <InfiniteListWithViewModelsCard
        additionalHeaderActions={this.headerActions()}
        tableData={tableData}
        dataWithSelect={dataWithSelect}
        handleChangeDataWithSelect={handleChangeDataWithSelect}
        handleChangeSelectAll={handleChangeSelectAll}
        title={I18n.t('users.usersManager.table.title')}
        headersFullWidthMode={this.fullScreenColumns}
        headersCompressedMode={this.compressedColumn}
        mode={mode}
        minRows={minRows}
        fullscreen={false}
        disabled={isEditMode}
        selectable={isMultiSelectShown && mode === FULL_WIDTH_MODE_TABLE}
        selectAll={selectAll}
        entityType={I18n.t('entitiesTypes.user')}
        selectedEntityIndex={selectedUserIndex}
        className={classes.usersTable__table}
        classNamesObj={tableClassNameObj}
        handleClickRow={handleClickRow}
        loadData={loadData}
      />
    );
  }
}

UsersTable.propTypes = {
  classes: PropTypes.object.isRequired,
  customClassNames: PropTypes.object,
  tableData: PropTypes.array,
  dataWithSelect: PropTypes.array,
  selectAll: PropTypes.bool,
  minRows: PropTypes.number,
  isEditMode: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  selectedUserIndex: PropTypes.number,

  loadData: PropTypes.func.isRequired,
  handleClickRow: PropTypes.func,
  handleChangeDataWithSelect: PropTypes.func,
  handleChangeSelectAll: PropTypes.func,
  handleForgetUsersByIdList: PropTypes.func,
};

UsersTable.defaultProps = {
  tableData: [],
  dataWithSelect: [],
  selectAll: false,
  minRows: 10,
  customClassNames: {},
  isEditMode: false,
  selectedUserIndex: null,
  handleClickRow: () => {},
  handleChangeDataWithSelect: () => {},
  handleChangeSelectAll: () => {},
  handleForgetUsersByIdList: () => {},
};

export default withStyles(style)(UsersTable);
