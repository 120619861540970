import React from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {withStyles} from '@material-ui/core/styles';

import {NavPills} from '../../material-dashboard-pro-react/components';

import {UsersManager, UsersConfigurer} from '../../containers';
import {MessageBox, RenderOrEmpty} from '../../components';

import style from './style';

class Users extends React.PureComponent {
  onTabChange = (tabIndex) => {
    if (tabIndex === 1) {
      this.props.history.push('/users/configure');
    } else {
      this.props.history.push('/users');
    }
  };

  render() {
    const {accountInfo, classes, tab} = this.props;

    return (
      <div className={classes.users}>
        <RenderOrEmpty isShow={accountInfo.deviceAgentAllowed}>
          <NavPills
            color="secondary"
            className="root_fullScreen"
            wrapperClassName="tab__contentWrapper_fullScreen"
            contentClassName="tab__content_fullScreen"
            isSwipeable={false}
            active={tab}
            onChange={this.onTabChange}
            tabs={[
              {
                tabButton: I18n.t('users.usersManager.name'),
                tabContent: <UsersManager />,
              },
              {
                tabButton: I18n.t('users.usersConfigurer.name'),
                tabContent: <UsersConfigurer />,
              },
            ]}
          />
        </RenderOrEmpty>
        <RenderOrEmpty isShow={!accountInfo.deviceAgentAllowed} >
          <MessageBox message={I18n.t('clients.messages.agentUsingNotAllowed')} />
        </RenderOrEmpty>
      </div>
    );
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
  tab: PropTypes.number,
  history: PropTypes.object.isRequired,
};

Users.defaultProps = {
  tab: 0,
};

const mapStateToProps = (state) => ({
  accountInfo: state.operatorLayoutReducer.accountInfo,
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(withRouter(Users)));
