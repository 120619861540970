export const types = {
  SET_SELECTED_USER: 'SET_SELECTED_USER',
  SET_SELECTED_USER_INDEX: 'SET_SELECTED_USER_INDEX',
  RESET_SELECTED_USER_INDEX: 'RESET_SELECTED_USER_INDEX',
  SET_GROUP_NAMES: 'SET_GROUP_NAMES',
  SET_SEARCH_OPTION: 'SET_SEARCH_OPTION',
  SET_USERS: 'SET_USERS',
  ADD_USERS: 'ADD_USERS',
  RESET_USERS_MANAGER_DATA: 'RESET_USERS_MANAGER_DATA',
  RESET_FILTERS: 'RESET_FILTERS',
};

export enum UserServices {
  ACTIVE_DIRECTORY = 'ACTIVE_DIRECTORY',
  ENTRA_ID = 'ENTRA_ID',
}

export const userServicesNames = {
  [UserServices.ACTIVE_DIRECTORY]: 'Directory Services',
  [UserServices.ENTRA_ID]: 'Entra ID',
};
