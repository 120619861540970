import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

import {getGroupsTree} from '../../api/categories';
import {METHODS} from '../../constants';
import {makeUrl, post, postWithCsvResponse} from '../../utils/fetcher';
import {resetPolicies} from '../Policy/action';
import {resetCampuses} from '../Campus/action';

import {FILTERS, TABS, widgetKeys, COMPONENTS, DOMAIN_TYPES} from './constants';
import {getTabFilters, getCategories as getCategoriesSelector} from './selectors';
import {getCategoriesSubtypesOptions, processFilters, normalizeSearchOptions} from './service';

export const types = {
  SET_SEARCH_OPTION: 'SET_INSIGHTS_SEARCH_OPTION',
  SET_DOMAIN_TYPE: 'SET_DOMAIN_TYPE',
  SET_FILTER: 'SET_INSIGHTS_FILTER',
  SET_DATA: 'SET_INSIGHTS_DATA',
  SET_COMPONENTS: 'SET_INSIGHTS_COMPONENTS',
  SET_SELECTED_RECORDS: 'SET_INSIGHTS_SELECTED_RECORDS',
  SET_CATEGORIES: 'SET_INSIGHTS_CATEGORIES',
  SET_OPTIONS: 'SET_INSIGHTS_OPTIONS',
  LOAD_MORE_INSIGHTS_LOGS: 'LOAD_MORE_INSIGHTS_LOGS',
  RESET_INSIGHTS_LOGS: 'RESET_INSIGHTS_LOGS',
  SET_INSIGHTS_LOGS: 'SET_INSIGHTS_LOGS',
  RESET_STATE: 'RESET_INSIGHTS_STATE',
  RESET_TAB_FILTERS: 'RESET_TAB_FILTERS',
  DISABLE_FILTERS: 'DISABLE_FILTERS',
  ENABLE_FILTERS: 'ENABLE_FILTERS',
  SET_FIELD_VALIDATION: 'SET_FIELD_VALIDATION',
};

export const INSIGHTS_METHODS = {
  [TABS.DOMAIN]: {
    [DOMAIN_TYPES.DOMAIN]: METHODS.GET_DOMAIN_STATISTICS,
    [DOMAIN_TYPES.FQDN]: METHODS.GET_FQDN_STATISTICS,
  },
  [TABS.TLD]: METHODS.GET_TOP_LEVEL_DOMAIN_STATISTICS,
  [TABS.CATEGORY]: METHODS.GET_CATEGORY_STATISTICS,
  [TABS.RECORD_TYPE]: METHODS.GET_RECORD_TYPE_STATISTICS,
  [COMPONENTS.LOGS]: METHODS.GET_INSIGHTS_LOGS,
};

const EXPORT_INSIGHTS_REQUESTS = {
  [TABS.TLD]: exportInsightsRequest,
  [TABS.CATEGORY]: exportInsightsRequest,
  [TABS.RECORD_TYPE]: exportInsightsRequest,
  [COMPONENTS.LOGS]: exportInsightLogsRequest,
  [COMPONENTS.DOMAINS]: exportInsightsRequest,
  [COMPONENTS.FQDNS]: exportInsightsRequest,
};

export function setSearchOption(tab, optionName, value) {
  return (dispatch) => dispatch({
    type: types.SET_SEARCH_OPTION,
    tab,
    optionName,
    value,
  });
}

export function setDomainType(value) {
  return (dispatch) => dispatch({
    type: types.SET_DOMAIN_TYPE,
    value,
  });
}

export function setFilter(tab, filterName, value) {
  return (dispatch) => dispatch({
    type: types.SET_FILTER,
    tab,
    filterName,
    value,
  });
}

export function getInsights(tab) {
  return (dispatch, getState) => {
    const state = getState();

    const domainType = tab === TABS.DOMAIN ? state.insightsReducer.domainType : null;

    const insightsMethod = tab === TABS.DOMAIN
      ? INSIGHTS_METHODS[TABS.DOMAIN][domainType]
      : INSIGHTS_METHODS[tab];

    const url = makeUrl(insightsMethod);

    const options = normalizeSearchOptions(tab, state.insightsReducer.searchOptions[tab]);
    const filters = getFilters(getTabFilters(tab)(state));
    const params = {...options, ...filters};

    return post(url, params, dispatch, true, widgetKeys[tab])
      .then((response) => {
        if (response && response.data) {
          const data = response.data;
          dispatch({
            type: types.SET_DATA,
            tab,
            data,
            domainType,
          });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to get insights:', error);
      });
  };
}

export function setInsightsComponents(tab, components) {
  return (dispatch) => dispatch({
    type: types.SET_COMPONENTS,
    tab,
    components,
  });
}

export function setInsightsSelectedRecords(tab, selectedRecords) {
  return (dispatch) => dispatch({
    type: types.SET_SELECTED_RECORDS,
    tab,
    selectedRecords,
  });
}

export function exportInsights(tab, currentComponentKey, filterData, hasRowsLimit = true) {
  let key = tab;
  if (!_isNil(currentComponentKey) && !_isEqual(currentComponentKey, tab)) {
    key = currentComponentKey;
  }

  const method = (key === DOMAIN_TYPES.DOMAIN || key === DOMAIN_TYPES.FQDN)
    ? INSIGHTS_METHODS[TABS.DOMAIN][key]
    : INSIGHTS_METHODS[key];

  const requester = EXPORT_INSIGHTS_REQUESTS[key];

  return requester(tab, method, filterData, hasRowsLimit);
}

function exportInsightsRequest(tab, method, filterData, hasRowsLimit) {
  const url = makeUrl(method);

  return (dispatch, getState) => {
    const state = getState();
    const options = normalizeSearchOptions(tab, state.insightsReducer.searchOptions[tab]);
    const filters = getFilters(getTabFilters(tab)(state));

    const params = {...options, ...filters, ...filterData};

    if (tab === TABS.CATEGORY && method === METHODS.GET_DOMAIN_STATISTICS) {
      params.fqdnCategories = params.category;
      params.fqdnCategoryType = params.categoryType;
      delete params.category;
      delete params.categoryType;
    }

    // no limit on rows for export, unless backend has it
    if (!hasRowsLimit) {
      delete params.rows;
    }
    return postWithCsvResponse(url, params, dispatch, true, widgetKeys[tab])
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to export Insights to CSV:', error);
      });
  };
}

function exportInsightLogsRequest(tab, method, filterData, hasRowsLimit) {
  const url = makeUrl(method);
  return (dispatch, getState) => {
    const state = getState();
    const options = normalizeSearchOptions(tab, state.insightsReducer.searchOptions[tab]);
    const filters = getFilters(getTabFilters(tab)(state));
    const params = filterData ? {...options, ...filters, ...filterData} : {...options, ...filters};

    // no limit on rows for export, unless backend has it
    if (!hasRowsLimit) {
      delete params.rows;
    }

    return postWithCsvResponse(url, params, dispatch, true, widgetKeys[tab])
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Unable to export Insight Logs to CSV:', error);
      });
  };
}

export function getFiltersAndOptions(tab, state) {
  const options = state.insightsReducer.searchOptions[tab];
  const filters = getFilters(getTabFilters(tab)(state));
  return {...options, ...filters};
}

export function getFilters(filters) {
  return Object.entries(filters).reduce((res, f) => {
    const filterValue = _get(f, 1, {});
    const {value, options, type} = filterValue;
    const processFilterFunction = processFilters[type];
    let resValue = value;
    if (!_isNil(value) && processFilterFunction) {
      resValue = processFilterFunction(value, options);
    }
    if (_isEmpty(resValue)) {
      return {...res};
    }
    return {
      ...res,
      [f[0]]: resValue,
    };
  }, {});
}

export function resetState() {
  return (dispatch) => {
    dispatch({type: types.RESET_STATE});
    dispatch(resetPolicies());
    dispatch(resetCampuses());
  };
}

export function resetTabFilters(tab) {
  return (dispatch) => {
    dispatch({
      type: types.RESET_TAB_FILTERS,
      tab,
    });
  };
}

export function getCategories() {
  return (dispatch) => dispatch(getGroupsTree())
    .then((categories) => dispatch({
      type: types.SET_CATEGORIES,
      categories,
    }));
}

export function updateCategorySubtypesOptions(tab, type) {
  return (dispatch, getState) => {
    const state = getState();
    const categories = getCategoriesSelector(state);
    const options = getCategoriesSubtypesOptions(categories, type);
    dispatch({
      type: types.SET_OPTIONS,
      filterName: FILTERS.CATEGORY,
      tab,
      options,
    });
  };
}

export function disableFilters(tab) {
  return (dispatch) => {
    dispatch({
      type: types.DISABLE_FILTERS,
      tab,
    });
  };
}

export function enableFilters(tab) {
  return (dispatch) => {
    dispatch({
      type: types.ENABLE_FILTERS,
      tab,
    });
  };
}

export function setValidation(tab, fieldId, fieldValidation) {
  return (dispatch) => {
    dispatch({
      type: types.SET_FIELD_VALIDATION,
      tab,
      filterName: fieldId,
      isValid: fieldValidation,
    });
  };
}
