const spaceBetweenStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
};

export const style = {
  mainContainer: {
    minWidth: '250px',
    flex: 1,
  },
  header: spaceBetweenStyle,
  header__titleOnly: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cell: {
    ...spaceBetweenStyle,
    //    textTransform: 'uppercase',
  },
  cell__text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  table: {
    overflowY: 'auto',
    maxHeight: '500px',
    minHeight: '150px',
  },
  addAllContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  addAllContainer__button: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
};
