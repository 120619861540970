const style = {
  icon: {
    verticalAlign: 'middle',
  },
  icon_entra: {
    verticalAlign: 'middle',
    marginLeft: '13px',
  },
};

export default style;
