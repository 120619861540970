import React from 'react';
import {I18n} from 'react-redux-i18n';
import {WithStyles, withStyles} from '@material-ui/core';

import {CustomButton} from '../../material-dashboard-pro-react/components';
import {RightAvailability} from '../../containers';
import {ACCESS_RIGHTS} from '../../constants';

import {style} from './style';

type UserActionsProps = WithStyles<typeof style> & {
  handleForget: () => void;
}

function UserActions({classes, handleForget}: UserActionsProps) {
  return (
    <div className={classes.userActions}>
      <RightAvailability
        key="forget-user-action"
        accessRights={[ACCESS_RIGHTS.USER_FORGET]}
      >
        <CustomButton
          color="primaryNoBackground"
          border="solid"
          customClasses="userActions__actionButton"
          onClick={handleForget}
          size="xs"
        >
          {I18n.t('users.usersManager.editUserForm.forgetUser')}
        </CustomButton>
      </RightAvailability>
    </div>
  );
}

export default withStyles(style)(UserActions);
