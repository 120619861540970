import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {withStyles} from '@material-ui/core';

import EntitiesListWithViewModesCard
  from '../EntitiesListWithViewModesCard/EntitiesListWithViewModesCard';
import {ACCESS_RIGHTS} from '../../constants';
import style from './style';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.PERSONA_CREATE],
};

/**
 * Personas table
 * @param {persona} personas array of persona objects with fields:
 * - `name`: string, persona name
 * - `description`: string, persona description
 * - `userStats`: object, user stats
 * - `adDomainId`: string, AD domain id
 * @param {handleClickButtonNew} handleClickButtonNew - callback for new button
 * @param {handleClickPersonaRow} handleClickPersonaRow - callback for clicking row
 * @param {isEditMode} isEditMode - boolean
 * @param {mode} mode - string, mode of table
 * @param {selectedPersonaIndex} selectedPersonaIndex - number
 */
function PersonaTable(props) {
  const renderUsersRow = (online, offline) => {
    const totalUsers = online + offline;
    return (
      <div className={props.classes.cellContainer}>
        <span>
          {totalUsers}
        </span>
        &nbsp;/&nbsp;
        <span className={props.classes.usersOnline}>
          {online}
        </span>
        &nbsp;/&nbsp;
        <span className={props.classes.usersOffline}>
          {offline}
        </span>
      </div>
    );
  };

  const renderCell = (value) => <div className={props.classes.cellContainer}>{value ?? ''}</div>;

  const headersFullWidthMode = [
    {
      Header: renderCell(I18n.t('users.usersConfigurer.personaName')),
      accessor: 'name',
      sortable: false,
      filterable: false,
      Cell: (row) => renderCell(row.value),
    },
    {
      Header: renderCell(I18n.t('users.usersConfigurer.description')),
      accessor: 'description',
      sortable: false,
      filterable: false,
      Cell: (row) => renderCell(row.value),
    },
    {
      Header: renderCell(I18n.t('users.usersConfigurer.users')),
      sortable: false,
      filterable: false,
      Cell: (row) => renderUsersRow(row.original.userStats.ONLINE, row.original.userStats.OFFLINE),
    },
    {
      Header: renderCell(I18n.t('users.usersConfigurer.serviceDomain')),
      accessor: 'adDomainFqdn',
      sortable: false,
      filterable: false,
      Cell: (row) => renderCell(row.value),
    },
    {
      Header: renderCell(I18n.t('users.usersConfigurer.entraTenant')),
      accessor: 'entraTenantName',
      sortable: false,
      filterable: false,
      Cell: (row) => renderCell(row.value),
    },
  ];
  const headersCompressedMode = [{
    Header: renderCell(I18n.t('users.usersConfigurer.personaName')),
    accessor: 'name',
    sortable: false,
    filterable: false,
    Cell: (row) => renderCell(row.value),
  }];

  return (
    <EntitiesListWithViewModesCard
      mode={props.mode}
      headersFullWidthMode={headersFullWidthMode}
      headersCompressedMode={headersCompressedMode}
      tableData={props.personas}
      disabled={props.isEditMode}
      title={I18n.t('users.usersConfigurer.persona.tableTitle')}
      entityType={I18n.t('entitiesTypes.persona')}
      selectedEntityIndex={props.selectedPersonaIndex}
      handleClickButtonNew={props.handleClickButtonNew}
      handleClickRow={props.handleClickPersonaRow}
      accessRights={rightAvailabilityMap}
      showOnAccessDenyButtonCreate={true}
    />
  );
}

PersonaTable.propTypes = {
  personas: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    userStats: PropTypes.object.isRequired,
    adDomainId: PropTypes.string,
  })).isRequired,
  handleClickButtonNew: PropTypes.func.isRequired,
  handleClickPersonaRow: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  selectedPersonaIndex: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(PersonaTable);
