import React from 'react';

import {withStyles, WithStyles} from '@material-ui/core';

import {EntraID, Windows} from '../../icons';

import {UserServices, userServicesNames} from '../../containers/UsersManager/constants';

import styles from './style';

type UserServiceIconProps = WithStyles<typeof styles> & {
  service: UserServices;
};

export function UserServiceIcon({service, classes}: UserServiceIconProps) {
  const IconsMap = {
    [UserServices.ACTIVE_DIRECTORY]: <Windows className={classes.icon} title={userServicesNames[service]} />,
    [UserServices.ENTRA_ID]: <EntraID className={classes.icon_entra} title={userServicesNames[service]} />,
  };

  return (
    IconsMap[service] ?? ''
  );
}

export default withStyles(styles)(UserServiceIcon);
