import {I18n} from 'react-redux-i18n';
import _isEmpty from 'lodash/isEmpty';

import {getExternal, buildQueryParams} from '../../utils/fetcher';
import {LINKS, MODAL_WINDOW_NOTIFY_TYPE} from '../../constants';
import {openModalWindow} from '../../scout-dns/layouts/Operator/action';

export function getTenantByDomain(appId, domain, state, redirectUrl) {
  const queryParams = buildQueryParams({
    client_id: appId,
    state,
    redirect_uri: redirectUrl,
  });

  if (!_isEmpty(domain)) {
    return processEntraDomain(domain.trim(), queryParams);
  }
  return processDefaultDomain(queryParams);
}

function processEntraDomain(domain, queryParams) {
  const loginLink =
     `${LINKS.ENTRA_ADMIN_CONSENT_URL}${domain.trim()}/${LINKS.ENTRA_OPENID_CONFIG_PATH}`;
  return (dispatch) => getExternal(loginLink, dispatch)
    .then((response) => {
      if (!response || !response.token_endpoint) {
        // eslint-disable-next-line no-console
        console.log(`Unable to get tenant name from ${loginLink}, got response `, response);
        throw new Error('Unable to get tenant name');
      }
      const parts = response.token_endpoint.split('/');
      if (parts.length < 3) {
        // eslint-disable-next-line no-console
        console.log(`Unable to get tenant name from ${loginLink}, got response `, response);
        throw new Error('Unable to get tenant name');
      }
      const tenantId = parts[3];
      const tenant = (tenantId !== undefined && tenantId !== null && tenantId.trim() !== '')
        ? tenantId
        : LINKS.ENTRA_COMMON_TENANT;

      // eslint-disable-next-line max-len
      const consentLink = `${LINKS.ENTRA_ADMIN_CONSENT_URL}/${tenant}/${LINKS.ENTRA_ADMIN_CONSENT_ENDPOINT}?${queryParams}`;
      redirectToUrl(consentLink);
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch(openModalWindow(
        I18n.t('users.usersConfigurer.persona.entraId.checkDomain'),
        MODAL_WINDOW_NOTIFY_TYPE.INFO,
      ));
    });
}

function processDefaultDomain(queryParams) {
  const consentLink =
    // eslint-disable-next-line max-len
    `${LINKS.ENTRA_ADMIN_CONSENT_URL}/${LINKS.ENTRA_COMMON_TENANT}/${LINKS.ENTRA_ADMIN_CONSENT_ENDPOINT}?${queryParams}`;
  redirectToUrl(consentLink);
  return ({
    type: 'no action',
  });
}

function redirectToUrl(url) {
  window.open(url, '_self')?.focus();
}
