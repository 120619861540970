export const getPersonaBaseSettings = (persona) => ({
  id: persona?.id ?? '',
  name: persona?.name ?? '',
  description: persona?.description ?? '',
});

export const getPersonaDirectoryServiceSettings = (persona) => ({
  domain: persona?.adDomain?.fqdn ?? '',
  domainId: persona?.adDomain?.id,
  adConfiguredGroups: persona?.adConfiguredGroups ?? [],
  adObservedGroups: persona?.adObservedGroups ?? [],
});

export const getPersonaEntraIdSettings = (persona) => ({
  entraTenantId: persona.entraTenant?.id,
  entraTenantName: persona.entraTenant?.name ?? '',
  entraConfiguredGroups: persona.entraConfiguredGroups ?? [],
  entraObservedGroups: persona.entraObservedGroups ?? [],
});
