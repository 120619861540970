import {createStyles} from '@material-ui/core';

export const style = createStyles({
  userActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
});
