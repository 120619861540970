import _cloneDeep from 'lodash/cloneDeep';
import initState from '../../reducers/redux-initial-state';

import {types} from './action';

export default (state = initState.personaEditFormReducer, action) => {
  switch (action.type) {
    case types.RESET_PERSONA_FORM_VALUES: {
      return {
        ...state,
        directoryServices: initState.personaEditFormReducer.directoryServices,
        entraId: initState.personaEditFormReducer.entraId,
        baseSettingsObject: initState.personaEditFormReducer.baseSettingsObject,
      };
    }
    case types.UPDATE_BASE_SETTING: {
      return {
        ...state,
        baseSettingsObject: action.data,
      };
    }
    case types.BIND_DOMAIN: {
      return {
        ...state,
        directoryServices: {
          ...state.directoryServices,
          domain: action.domain.fqdn,
          domainId: action.domain.id,
        },
      };
    }
    case types.BIND_TENANT: {
      return {
        ...state,
        entraId: {
          ...state.entraId,
          entraTenantId: action.tenant.id,
          entraTenantName: action.tenant.name,
        },
      };
    }
    case types.UPDATE_CONFIGURED_GROUPS: {
      return {
        ...state,
        directoryServices: {
          ...state.directoryServices,
          adConfiguredGroups: action.data,
        },
      };
    }
    case types.UPDATE_ENTRA_CONFIGURED_GROUPS: {
      return {
        ...state,
        entraId: {
          ...state.entraId,
          entraConfiguredGroups: action.data,
        },
      };
    }
    case types.UPDATE_PERSONA_AD_OBSERVED_GROUPS: {
      return {
        ...state,
        directoryServices: {...state.directoryServices, adObservedGroups: action.groups},

      };
    }
    case types.UPDATE_ENTRA_OBSERVED_GROUPS: {
      return {
        ...state,
        entraId: {...state.entraId, entraObservedGroups: action.groups},

      };
    }
    case types.SET_DIRECTORY_SERVICES: {
      return {
        ...state,
        directoryServices: _cloneDeep(action.data),
      };
    }
    case types.SET_ENTRA_ID: {
      return {
        ...state,
        entraId: _cloneDeep(action.data),
      };
    }
    case types.SET_TENANT_SYNC_TIME: {
      return {
        ...state,
        tenantLastSyncAt: action.data,
      };
    }

    default:
      return state;
  }
};
