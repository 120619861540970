import {categoryOptions,
  tldTypeOptions,
  COMPONENT_TYPES as insightsComponentTypes,
  DEFAULT_SEARCH_OPTIONS as defaultInsightsSearchOptions,
  FILTERS as insightsFilters,
  TABS as insightsTabs,
  DOMAIN_TYPES as domainTypes,
  RECORD_TYPES,
  MAX_ROWS_OPTION,
  COMPONENTS,
  widgetKeys as insightsWidgetKeys} from '../app-common/Insights/constants';
import {widgetKeys as mainDashboardWidgetKeys} from '../scout-dns/app/Dashboard/constants';
import {widgetKeys as campusWidgetKeys} from '../app-common/Campus/constants';
import {widgetKeys as clientWidgetKeys} from '../containers/ClientDashboard/constants';
import {widgetKeys as logWidgetKeys} from '../app-common/Log/constants';
import {widgetKeys as organizationsWidgetKeys} from '../app-common/Organizations/constants';
import {widgetKeys as organizationSitesKeys} from '../containers/OrganizationSitesTab/constants';
import {widgetKeys as organizationProfilesKeys}
  from '../containers/OrganizationProfilesTab/constants';
import {widgetKeys as usersWidgetKeys} from '../app-common/Users/constants';

import {DEFAULT_WIDGET_KEY, RELAY_STATUSES, STATES_ENTITY} from '../constants';
import {isValidIpAndCidrMask} from '../utils/validators';
import {createLoadingWidgetReducers} from './utils';

export default {
  authReducer: {
    loggedIn: false,
    isLoggedInFailed: false,
    isSecondFactorFailed: false,
    show2FAForm: false,
    authCodeResetAt: '',
    authCodeChecked: false,
  },
  campusEditFormReducer: {
    isValidCampusInfo: true,
    editableCampusInfo: {
      name: '',
      address: '',
    },
  },
  networkReducer: {
    networks: [],
  },
  campusReducer: {
    campuses: [],
    campusesListData: [],
    campusesTableData: [],
    campusState: STATES_ENTITY.NONE,
    isEditMode: false,
    selectedCampus: {
      id: null,
      name: '',
      address: '',
    },
    selectedCampusIndex: -1,
    selectedPeriodIndex: 0,
    policies: [],
  },
  campusNetworksTabReducer: {
    isEditMode: false,
    networkState: STATES_ENTITY.NONE,
    networks: [],
    networksTableData: [],
    networksStatusesObject: {},
    selectedNetwork: {
      id: '',
      domainName: '',
      enabled: true,
      name: '',
      ip: [],
      policyId: '',
      policyName: '',
      blockPageId: '',
      blockPageName: '',
    },
    selectedNetworkIndex: -1,
  },
  campusRelaysTabReducer: {
    editMode: false,
    relayState: STATES_ENTITY.NONE,
    relays: [],
    selectedRelayIndex: -1,
    selectedRelayTableRowData: {
      network: '',
      id: '',
      version: '',
      status: RELAY_STATUSES.OFFLINE,
      enabled: false,
    },
    networkAgentAllowed: true,
  },
  changePasswordReducer: {
    isPasswordChanged: false,
    enteredIncorrectCurrentPassword: false,
  },
  campusLocalDomainsTabReducer: {
    isEditMode: false,
    localDomainState: STATES_ENTITY.NONE,
    localDomains: [],
    localDomainsTableData: [],
    selectedLocalDomain: {
      network: '',
      domain: '',
      ip: '',
      networkId: null,
      locationId: null,
      forAllNetworks: false,
    },
    selectedLocalDomainIndex: -1,
    networkAgentAllowed: true,
  },
  campusLocalForwardingTabReducer: {
    isEditMode: false,
    localForwardingState: STATES_ENTITY.NONE,
    localForwardings: [],
    localForwardingsTableData: [],
    selectedLocalForwarding: {
      subnet: '',
      domain: '',
      ips: [''],
      subnetId: null,
      locationId: null,
      forAllSubnets: false,
    },
    selectedLocalForwardingIndex: -1,
    networkAgentAllowed: true,
    subnets: [],
  },
  campusSubnetsTabReducer: {
    isEditMode: false,
    subnetState: STATES_ENTITY.NONE,
    subnetsTableData: [],
    subnetsStatusesObject: {},
    subnets: [],
    selectedSubnet: {
      enabled: true,
      name: '',
      ipAddressRange: [],
      policyId: null,
      policyName: '',
      networkName: '',
      networkId: null,
      locationId: null,
      forAllNetworks: false,
    },
    selectedSubnetIndex: -1,
    networkAgentAllowed: true,
  },
  blockPageReducer: {
    blockPages: [],
    blockPagesListData: [],
    blockPagesTableData: [],
    blockPageState: STATES_ENTITY.NONE,
    isEditMode: false,
    selectedBlockPage: {
      id: null,
      name: '',
      description: '',
      contentTitle: '',
      contentHeader: '',
      contentResponse: '',
      contentLogoMessage: '',
      contentNonDefaultEmail: '',
      contentHasReviewRequests: true,
      contentHasUserEmailInput: true,
      contentHasNonDefaultSendEmail: false,
      contentHasLogoInserted: false,
      contentLogoURL: '',
      contentLogoName: '',
      threatLogoMessage: '',
      threatNonDefaultEmail: '',
      threatHasReviewRequests: true,
      threatHasUserEmailInput: true,
      threatHasNonDefaultSendEmail: false,
      threatHasLogoInserted: false,
      threatLogoURL: '',
      threatLogoName: '',
    },
    selectedBlockPageIndex: -1,
    selectedPeriodIndex: 0,
  },
  blockPageEditFormReducer: {
    editableBlockPageInfo: {
      id: null,
      name: '',
      description: '',
      contentTitle: '',
      contentHeader: '',
      contentResponse: '',
      contentLogoMessage: '',
      contentNonDefaultEmail: '',
      contentHasReviewRequests: true,
      contentShouldHideIPs: true,
      contentHasUserEmailInput: true,
      contentIsUserEmailRequired: false,
      contentHasNonDefaultSendEmail: false,
      contentHasLogoInserted: false,
      contentLogoURL: '',
      contentLogoName: '',
      contentLogo: null,
      threatLogoMessage: '',
      threatNonDefaultEmail: '',
      threatHasReviewRequests: true,
      threatHasUserEmailInput: true,
      threatHasNonDefaultSendEmail: false,
      threatHasLogoInserted: false,
      threatLogoURL: '',
      threatLogoName: '',
      default: false,
    },
    isValidBlockPageInfo: false,
  },
  singleSignOnReducer: {
    singleSignOnConfigurations: [],
    selectedSingleSignOnConfiguration: {
      id: null,
      callbackURL: process.env.REACT_APP_AZURE_REDIRECT_URI,
      oauthTenantId: '',
      clientId: '',
      selectedProviderAdminRoles: [],
      selectedProviderViewerRoles: [],
      selectedProviderServiceDeskRoles: [],
      ssoEnabled: true,
    },
    isEditMode: true,
    singleSignOnState: STATES_ENTITY.NONE,
  },
  singleSignOnEditFormReducer: {
    editableSingleSignOnConfiguration: {
      callbackURL: process.env.REACT_APP_AZURE_REDIRECT_URI,
      oauthTenantId: '',
      clientId: '',
      selectedProviderAdminRoles: [],
      selectedProviderViewerRoles: [],
      selectedProviderServiceDeskRoles: [],
      ssoEnabled: true,
    },
    isValidSingleSignOnConfiguration: false,
  },
  clientsManagerReducer: {
    searchOptions: {
      agentStatus: null,
      onSiteStatus: null,
      clientName: '',
      profileId: null,
    },
    selectedClient: {
      clientName: '',
      osName: '',
      osIconKey: '',
      profileId: null,
      policyId: null,
      activePolicyId: null,
      version: '',
      username: '',
      hostname: '',
      lastSyncAt: '',
      lastWanIp: null,
      latLamIp: null,
      lastLocationId: null,
      lastLocationName: '',
      profileName: '',
      localDomain: '',
      activePolicyName: '',
      activePolicyType: null,
      agentStatus: null,
      uninstall: false,
      enabled: false,
    },
    profileNames: [],
    policies: [],
    isLastClientsPage: false,
    clients: [],
    tableData: [],
    isEditMode: false,
    selectedClientIndex: -1,
    clientState: STATES_ENTITY.NONE,
  },
  clientEditFormReducer: {
    isValidClientInfo: true,
    editableClientInfo: {
      clientName: '',
      osName: '',
      osIconKey: '',
      profileId: null,
      policyId: null,
      activePolicyId: null,
      version: '',
      username: '',
      hostname: '',
      lastSyncAt: '',
      lastWanIp: null,
      latLamIp: null,
      lastLocationId: null,
      lastLocationName: '',
      profileName: '',
      clientDomain: '',
      activePolicyName: '',
      activePolicyType: null,
      agentStatus: null,
      uninstall: false,
      enabled: false,
    },
    selectedProfileIndex: -1,
    selectedPolicyIndex: -1,
    selectedPeriodIndex: 0,
    policies: [],
    averageLatency: 0,
    requestsPerSecond: 0,
    requestsByDecision: {
      ALLOWED: 0,
      FORBIDDEN: 0,
    },
    userRequestsGraph: {
      chartData: [],
    },
    threatStats: [],
  },
  clientSessionsReducer: {
    sessions: [],
  },
  userSessionsReducer: {
    sessions: [],
  },
  clientsConfigurerReducer: {
    platforms: [],
    installers: [],
    profiles: [],
    policies: [],
    isEditMode: false,
    selectedProfileIndex: -1,
    selectedProfile: {
      id: null,
      name: '',
      description: '',
      defaultPolicyId: null,
      hasDynamicPolicies: false,
      useUserPolicy: false,
      onsitePolicyId: null,
      offsitePolicyId: null,
      onsitePolicySiteDefault: false,
      onsitePolicyProfileDefault: true,
      offsitePolicyProfileDefault: true,
      hideTrayIcon: false,
      blockPageId: '',
      blockPageName: '',
      hasIPLayerFilters: false,
      ipLayerFilterLevel: 1,
      ipControlLists: [],
    },
    profileToCopy: {
      id: null,
      name: '',
      description: '',
      defaultPolicyId: null,
      hasDynamicPolicies: false,
      useUserPolicy: false,
      onsitePolicyId: null,
      offsitePolicyId: null,
      onsitePolicySiteDefault: false,
      onsitePolicyProfileDefault: true,
      offsitePolicyProfileDefault: true,
      hideTrayIcon: false,
      hasIPLayerFilters: false,
      ipLayerFilterLevel: 1,
      ipControlLists: [],
    },
    profileState: STATES_ENTITY.NONE,
  },
  clientProfileEditFormReducer: {
    isValidProfileInfo: true,
    settings: {
      editableProfileInfo: {
        name: '',
        description: '',
        defaultPolicyId: null,
        hasDynamicPolicies: false,
        useUserPolicy: false,
        onsitePolicyId: null,
        offsitePolicyId: null,
        onsitePolicySiteDefault: false,
        onsitePolicyProfileDefault: true,
        offsitePolicyProfileDefault: true,
        hideTrayIcon: false,
        blockPageId: '',
        blockPageName: '',
        hasIPLayerFilters: false,
        ipLayerFilterLevel: 1,
        ipControlLists: [],
      },
      selectedProfileTemplateIndex: -1,
    },
  },
  clientProfileLocalForwardingFormReducer: {
    localDomains: [],
    selectedLocalDomain: {
      domain: '',
      resolverIps: [],
      resolvers: '',
      useDeviceResolvers: true,
    },
    selectedLocalDomainIndex: -1,
    isEditMode: false,
    localForwardingState: STATES_ENTITY.NONE,
  },
  clientLocalForwardingEditFormReducer: {
    editableLocalForwardingInfo: {
      domain: '',
      resolverIps: [],
      useDeviceResolvers: true,
    },
    isValidLocalForwardingInfo: true,
  },
  clientProfileInstallFormReducer: {
    bundles: [],
    selectedBundle: {
      osId: null,
      platformName: '',
      architectureId: null,
      architectureName: '',
      durationMilli: -1,
      durationLabel: '',
      installs: {
        used: 0,
        max: 1,
      },
      link: '',
    },
    selectedBundleIndex: -1,
    isEditMode: false,
    platforms: [],
    architectures: [],
  },
  clientBundleEditFormReducer: {
    architectures: [],
    durations: [],
    editableBundleInfo: {
      osId: null,
      platformName: '',
      architectureId: null,
      architectureName: '',
      durationMilli: -1,
      durationLabel: '',
      installs: {
        used: 1,
        max: 1,
      },
      maxInstalls: '1',
      link: '',
    },
    isValidBundleInfo: true,
    selectedPlatformIndex: -1,
    selectedArchitectureIndex: -1,
    selectedDurationIndex: -1,
    bundleState: STATES_ENTITY.NONE,
  },
  dashboardReducer: {
    averageLatency: 0,
    blocksStats: {
      applications: 0,
      content: 0,
      threats: 0,
      whiteBlackList: 0,
    },
    campusesStats: {},
    networksStats: {
      up: 0,
      down: 0,
    },
    requestsByDecision: {
      FORBIDDEN: 0,
      ALLOWED: 0,
    },
    requestsPerSecond: 0,
    requestsPerNetwork: {
      tableData: [],
    },
    top5Categories: {
      FORBIDDEN: {
        tableData: [],
        chartData: [],
      },
      ALLOWED: {
        tableData: [],
        chartData: [],
      },
    },
    top5Domains: {
      FORBIDDEN: {
        tableData: [],
      },
      ALLOWED: {
        tableData: [],
      },
    },
    userRequestsGraph: {
      chartData: [],
    },
    threats: [],
    clientCountStats: {
      online: 0,
      offline: 0,
    },
    orgsAndSitesStats: {
      orgs: 0,
      sites: 0,
    },
  },
  filtersReducer: {},
  organizationsReducer: {
    organizations: [],
    organizationsTableData: [],
    networkStatsByOrganization: {},
    organizationState: STATES_ENTITY.NONE,
    isEditMode: false,
    selectedOrganization: {
      id: null,
      name: '',
      description: '',
    },
    selectedOrganizationIndex: -1,
    selectedPeriodIndex: 0,
    averageLatency: 0,
    requestsPerSecond: 0,
    requestsByDecision: {
      ALLOWED: 0,
      FORBIDDEN: 0,
    },
    userRequestsGraph: {
      chartData: [],
    },
    threatStats: [],
  },
  organizationEditFormReducer: {
    editableOrganizationInfo: {
      name: '',
      description: '',
    },
    isValidOrganizationInfo: true,
    userRequestsGraph: {
      chartData: [],
    },
  },
  organizationProfilesTabReducer: {
    profilesInOrganization: [],
    profilesNotInOrganization: [],
    profilesInOrganizationStats: {},
    isEditMode: false,
  },
  organizationPersonasTabReducer: {
    personasInOrganization: [],
    personasNotInOrganization: [],
    isEditMode: false,
  },
  organizationSitesTabReducer: {
    sitesInOrganization: [],
    sitesNotInOrganization: [],
    networkStats: {},
    siteStats: {},
    isEditMode: false,
  },
  organizationDashboardReducer: {
    locations: [],
    clientProfiles: [],
    userRequestsGraph: {
      chartData: [],
    },
  },
  organizationSitesTabEditFormReducer: {
    sitesInOrganization: [],
    sitesNotInOrganization: [],
    isEditMode: false,
  },
  organizationPersonasTabEditFormReducer: {
    personasInOrganization: [],
    personasNotInOrganization: [],
    isEditMode: false,
  },
  organizationProfilesTabEditFormReducer: {
    profilesInOrganization: [],
    profilesNotInOrganization: [],
    isEditMode: false,
  },
  operatorOrganizationsTableReducer: {
    allOrganizations: [],
    rows: [],
  },
  ipListReducer: {
    ipTableData: [],
    anycastIPs: {},
  },
  loadingReducer: {
    [DEFAULT_WIDGET_KEY]: {
      loadingMap: {},
      isLoading: false,
    },
    ...createLoadingWidgetReducers(mainDashboardWidgetKeys),
    ...createLoadingWidgetReducers(insightsWidgetKeys),
    ...createLoadingWidgetReducers(campusWidgetKeys),
    ...createLoadingWidgetReducers(clientWidgetKeys),
    ...createLoadingWidgetReducers(logWidgetKeys),
    ...createLoadingWidgetReducers(organizationsWidgetKeys),
    ...createLoadingWidgetReducers(organizationSitesKeys),
    ...createLoadingWidgetReducers(organizationProfilesKeys),
    ...createLoadingWidgetReducers(usersWidgetKeys),
  },
  logReducer: {
    tableData: [],
    clientNames: [],
    organizations: [],
  },
  localDomainEditFormReducer: {
    editableLocalDomainInfo: {
      networkName: '',
      domain: '',
      ip: '',
      networkId: null,
      forAllNetworks: false,
    },
    isValidLocalDomainInfo: true,
  },
  localForwardingEditFormReducer: {
    subnets: [],
    editableLocalForwardingInfo: {
      subnetName: '',
      domain: '',
      ips: [''],
      subnetId: null,
      forAllSubnets: false,
    },
    isValidLocalForwardingInfo: true,
  },
  networkEditFormReducer: {
    editableNetworkInfo: {
      enabled: false,
      name: '',
      ip: [],
      ipAddressOrDomainName: '',
      policyId: '',
      policyName: '',
      blockPageId: '',
      blockPageName: '',
    },
    isValidNetworkInfo: true,
  },
  relayEditFormReducer: {
    editableRelayInfo: {
      network: '',
      id: '',
      version: '',
      status: RELAY_STATUSES.OFFLINE,
      enabled: false,
      adopted: false,
    },
  },
  operatorLayoutReducer: {
    accountType: '',
    notificationBannerData: {
      accountStatus: '',
      statusType: '',
      untilExpirationSeconds: 0,
    },
    accountInfo: {
      status: '',
      type: '',
      maxLocations: 0,
      maxNetworks: 0,
      networksPerLocation: 0,
      maxWBLists: 0,
      maxDomainsPerWBList: 0,
      networks: 0,
      apiAccessAllowed: false,
    },
    organizationInfo: null,
    modalWindow: {
      isOpen: false,
      text: '',
    },
  },
  personaReducer: {
    isEditMode: false,
    domains: [],
    tenants: [],
    personas: [],
    personasLoaded: false,
    personaState: STATES_ENTITY.NONE,
    personaTableData: [],
    policies: [],
    tenantAddedSuccess: null,
    selectedPersona: {
      id: '',
      name: '',
      description: '',
      adDomain: null,
      adConfiguredGroups: [],
      adObservedGroups: [],
      entraTenant: null,
      entraConfiguredGroups: [],
      entraObservedGroups: [],
    },
    selectedPersonaIndex: -1,
  },
  personaEditFormReducer: {
    tenantLastSyncAt: '',
    baseSettingsObject: {
      id: '',
      description: '',
      name: '',
    },
    directoryServices: {
      domain: '',
      domainId: '',
      adConfiguredGroups: [],
      adObservedGroups: [],
    },
    entraId: {
      entraTenantName: '',
      entraTenantId: '',
      entraConfiguredGroups: [],
      entraObservedGroups: [],
    },
  },
  policyReducer: {
    isEditMode: false,
    policies: [],
    policyState: STATES_ENTITY.NONE,
    policiesTableData: [],
    selectedPolicy: {
      advancedSettings: [],
      baseSettings: [],
      categories: [],
      description: '',
      name: '',
    },
    selectedPolicyIndex: -1,
  },
  policyEditFormReducer: {
    advancedSettingsObject: {},
    applicationsTabFormScheme: [],
    baseSettingsObject: {},
    categoriesObject: {},
    contentTabFormScheme: [],
    defaultPolicies: {
      highDefaultPolicy: {},
      lowDefaultPolicy: {},
      moderateDefaultPolicy: {},
      noneDefaultPolicy: {},
    },
    policyTemplateToCreate: {
      name: '',
      description: '',
      baseSettings: [],
      advancedSettings: [],
      categories: [],
      strategyStatusesObject: {},
      tldFilteringSettingsObject: {
        blockList: [],
        permitList: [],
      },
    },
    threatsTabFormScheme: [],
    selectedPolicyTemplateIndex: 0,
    strategyStatusesObject: {},
    isValidPolicyInfo: false,
    tldFilteringSettingsObject: {
      blockList: [],
      permitList: [],
    },
  },
  resetPasswordReducer: {
    isPasswordReset: false,
  },
  subnetEditFormReducer: {
    editableSubnetInfo: {
      enabled: false,
      name: '',
      ipAddressRange: [],
      networkName: '',
      networkId: null,
      policyId: null,
      policyName: '',
      locationId: null,
    },
    isValidSubnetInfo: true,
  },
  userAccountsReducer: {
    userAccounts: [],
    loggedAccount: {},
  },
  userOrganizationsReducer: {
    userOrganizations: [],
    currentOrganization: {
      id: '',
    },
    userRequestsGraph: {
      chartData: [],
    },
  },
  updateFirstAndLastNameReducer: {
    firstName: '',
    lastName: '',
  },
  updatePasswordReducer: {
    isPasswordUpdated: false,
    isPasswordUpdatingError: false,
  },
  whiteBlackListReducer: {
    isEditMode: false,
    selectedWhiteBlackList: {
      allowed: [],
      description: '',
      blocked: [],
      name: '',
      tags: [],
    },
    policiesAssigned: [],
    selectedWhiteBlackListIndex: -1,
    whiteBlackLists: [],
    whiteBlackListsTableData: [],
  },
  whiteBlackListEditFormReducer: {
    editableWhiteBlackListInfo: {
      allowed: '',
      description: '',
      forbidden: '',
      isGlobal: false,
      name: '',
      organizationId: null,
      tags: [],
    },
    isValidWhiteBlackListInfo: false,
  },
  tldListsReducer: {
    allTlds: [],
    top100TldNames: [],
    tldFiltersInfo: [],
    selectedTldList: {},
    policiesAssigned: [],
  },
  accessManagementReducer: {
    operators: [],
    isEditingMode: false,
  },
  apiAccessManagementReducer: {
    apiKeys: [],
    isEditingMode: false,
  },
  notificationsManagementReducer: {
    notificationTypes: [],
    notificationProfiles: [],
    isEditingMode: false,
  },
  subscriptionsReducer: {
    subscriptionDetails: {},
    invoices: [],
  },
  insightsReducer: {
    searchOptions: {
      [insightsTabs.DOMAIN]: {
        ...defaultInsightsSearchOptions,
      },
      [insightsTabs.TLD]: {...defaultInsightsSearchOptions},
      [insightsTabs.CATEGORY]: {
        ...defaultInsightsSearchOptions,
        rows: MAX_ROWS_OPTION,
      },
      [insightsTabs.RECORD_TYPE]: {
        ...defaultInsightsSearchOptions,
        rows: MAX_ROWS_OPTION,
      },
    },
    filters: {
      [insightsTabs.DOMAIN]: {
        [insightsFilters.SITE]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.CATEGORY_TYPE]: {
          isApplied: false,
          type: insightsComponentTypes.DROPDOWN,
          value: 0,
          options: categoryOptions,
        },
        [insightsFilters.CATEGORY]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.POLICY]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.RECORD_TYPE]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
          options: RECORD_TYPES,
        },
        [insightsFilters.NETWORK_IP]: {
          isApplied: false,
          type: insightsComponentTypes.INPUT,
          value: '',
          isValid: true,
          validator: isValidIpAndCidrMask,
        },
        [insightsFilters.SUBNET_IP]: {
          isApplied: false,
          type: insightsComponentTypes.INPUT,
          value: '',
          isValid: true,
          validator: isValidIpAndCidrMask,
        },
        [insightsFilters.USER]: {
          isApplied: false,
          type: insightsComponentTypes.INPUT,
          value: '',
          isValid: true,
        },
        [insightsFilters.CLIENT]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.NETWORK]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.PROFILE]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
      },
      [insightsTabs.TLD]: {
        [insightsFilters.TLD_TYPE]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
          options: tldTypeOptions,
        },
        [insightsFilters.SITE]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.CATEGORY_TYPE]: {
          isApplied: false,
          type: insightsComponentTypes.DROPDOWN,
          value: 0,
          options: categoryOptions,
        },
        [insightsFilters.CATEGORY]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.POLICY]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.RECORD_TYPE]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
          options: RECORD_TYPES,
        },
        [insightsFilters.NETWORK_IP]: {
          isApplied: false,
          type: insightsComponentTypes.INPUT,
          value: '',
          isValid: true,
          validator: isValidIpAndCidrMask,
        },
        [insightsFilters.SUBNET_IP]: {
          isApplied: false,
          type: insightsComponentTypes.INPUT,
          value: '',
          isValid: true,
          validator: isValidIpAndCidrMask,
        },
        [insightsFilters.USER]: {
          isApplied: false,
          type: insightsComponentTypes.INPUT,
          value: '',
          isValid: true,
        },
        [insightsFilters.CLIENT]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.NETWORK]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.PROFILE]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
      },
      [insightsTabs.CATEGORY]: {
        [insightsFilters.SITE]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.CATEGORY_TYPE]: {
          isApplied: false,
          type: insightsComponentTypes.DROPDOWN,
          value: 0,
          options: categoryOptions,
        },
        [insightsFilters.CATEGORY]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.POLICY]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.RECORD_TYPE]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
          options: RECORD_TYPES,
        },
        [insightsFilters.NETWORK_IP]: {
          isApplied: false,
          type: insightsComponentTypes.INPUT,
          value: '',
          isValid: true,
          validator: isValidIpAndCidrMask,
        },
        [insightsFilters.SUBNET_IP]: {
          isApplied: false,
          type: insightsComponentTypes.INPUT,
          value: '',
          isValid: true,
          validator: isValidIpAndCidrMask,
        },
        [insightsFilters.USER]: {
          isApplied: false,
          type: insightsComponentTypes.INPUT,
          value: '',
          isValid: true,
        },
        [insightsFilters.CLIENT]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.NETWORK]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.PROFILE]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
      },
      [insightsTabs.RECORD_TYPE]: {
        [insightsFilters.SITE]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.CATEGORY_TYPE]: {
          isApplied: false,
          type: insightsComponentTypes.DROPDOWN,
          value: 0,
          options: categoryOptions,
        },
        [insightsFilters.CATEGORY]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.POLICY]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.RECORD_TYPE]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
          options: RECORD_TYPES,
        },
        [insightsFilters.NETWORK_IP]: {
          isApplied: false,
          type: insightsComponentTypes.INPUT,
          value: '',
          isValid: true,
          validator: isValidIpAndCidrMask,
        },
        [insightsFilters.SUBNET_IP]: {
          isApplied: false,
          type: insightsComponentTypes.INPUT,
          value: '',
          isValid: true,
          validator: isValidIpAndCidrMask,
        },
        [insightsFilters.USER]: {
          isApplied: false,
          type: insightsComponentTypes.INPUT,
          value: '',
          isValid: true,
        },
        [insightsFilters.CLIENT]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.NETWORK]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
        [insightsFilters.PROFILE]: {
          isApplied: false,
          type: insightsComponentTypes.AUTOCOMPLETE,
          value: [],
        },
      },
    },
    filtersData: {
      categories: {},
    },
    components: {
      [insightsTabs.DOMAIN]: [COMPONENTS.DOMAINS],
      [insightsTabs.TLD]: [COMPONENTS.INSIGHTS],
      [insightsTabs.CATEGORY]: [COMPONENTS.INSIGHTS],
      [insightsTabs.RECORD_TYPE]: [COMPONENTS.INSIGHTS],
    },
    selectedRecords: {
      [insightsTabs.DOMAIN]: {},
      [insightsTabs.TLD]: {},
      [insightsTabs.CATEGORY]: {},
      [insightsTabs.RECORD_TYPE]: {},
    },
    data: {
      [insightsTabs.DOMAIN]: {
        [domainTypes.DOMAIN]: [],
        [domainTypes.FQDN]: [],
      },
      [insightsTabs.TLD]: [],
      [insightsTabs.CATEGORY]: [],
      [insightsTabs.RECORD_TYPE]: [],
    },
    areFiltersDisabled: {
      [insightsTabs.DOMAIN]: false,
      [insightsTabs.TLD]: false,
      [insightsTabs.CATEGORY]: false,
      [insightsTabs.RECORD_TYPE]: false,
    },
    domainType: domainTypes.DOMAIN,
  },
  insightsLogsReducer: {
    logs: [],
    domains: [],
    fqdns: [],
  },
  rowActionsReducer: {
    wbLists: [],
    areWbListsRequested: false,
  },
  responseDetailsReducer: {
    response: {},
  },
  relayBinaryListReducer: {
    builds: [],
    networkAgentAllowed: true,
  },
  performanceStatsWidgetReducer: {
    averageLatency: 0,
    requestsPerSecond: 0.0,
  },
  requestStatsWidgetReducer: {
    requestsByDecision: {
      FORBIDDEN: 0,
      ALLOWED: 0,
    },
    userRequestsGraph: {
    },
  },
  requestStatsByCategoriesWidgetReducer: {
    userRequestsByCategories: [],
  },
  threatStatsWidgetReducer: {
    threatStats: [],
  },
  universalDashboardReducer: {
    isShowDashboard: true,
  },
  usageBillingReducer: {
    period: null,
    selectedPeriodIndex: 0,
    queryCost: '',
    seatCost: '',
    invoiceDate: null,
    tableCostsCalculated: false,
    organizationUsageStats: [],
  },
  usersManagerReducer: {
    selectedUser: {},
    selectedUserIndex: -1,
    searchOptions: {
      userStatus: null,
      onSiteStatus: null,
      username: '',
      groupId: null,
    },
    groupNames: [],
    isLastUsersPage: false,
    users: [],
    tableData: [],
    isEditMode: false,
    userState: STATES_ENTITY.NONE,
  },
};
