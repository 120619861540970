import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';

import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import {StringArrayTd} from './StylizedTd';

import {widgetKeys} from '../../app-common/Log/constants';
import {LoadingLayout} from '../../layouts-common';
import {
  InfiniteTable,
  TableColumnsPicker,
  RenderOrEmpty,
} from '../index';
import {WbListActionsContainer, WbListsContainer} from '../../containers';

import LS from '../../utils/localStorage';
import style from './style';

class LogTable extends Component {
  constructor(props) {
    super(props);
    const organizationsAllowed = _get(this.props.accountInfo, 'organizationsAllowed', false);
    const deviceAgentAllowed = _get(this.props.accountInfo, 'deviceAgentAllowed', false);

    this.categoriesCellRenderer = (row) => (
      <StringArrayTd data={row.value} />
    );
    this.actionCellRenderer = (row) => {
      const domainName = _get(row, 'original.domainName', '');

      const areResponsesAvailable = _get(row, 'original.areResponsesAvailable', null);
      const queryID = _get(row, 'original.queryId', null);
      const showResponse = () => this.props.showResponse(row.original);
      const additionalActions = queryID ? [
        {
          label: I18n.t('logPage.table.inspect'),
          id: 'inspect-response',
          onClick: showResponse,
          disabled: !areResponsesAvailable,
        },
      ] : [];

      return (
        <WbListActionsContainer
          listProps={{domainName}}
          additionalActions={additionalActions}
        />
      );
    };

    const headers = [
      {
        name: 'dateTime',
        Header: I18n.t('logPage.table.dateTimeHeader'),
        accessor: 'dateTime',
        minWidth: 160,
        filterable: false,
        sortable: false,
        isShow: true,
      },
      {
        name: 'organizationName',
        Header: I18n.t('logPage.table.organizationName'),
        accessor: 'organizationName',
        minWidth: 150,
        sortable: false,
        filterable: false,
        isShow: organizationsAllowed,
      },
      {
        name: 'campusName',
        Header: I18n.t('logPage.table.campusNameHeader'),
        accessor: 'campusName',
        minWidth: 150,
        filterable: false,
        sortable: false,
        isShow: true,
      },
      {
        name: 'domainName',
        Header: I18n.t('logPage.table.domainName'),
        accessor: 'domainName',
        minWidth: 150,
        sortable: false,
        filterable: false,
        isShow: true,
      },
      {
        name: 'resolver',
        Header: I18n.t('logPage.table.resolver'),
        accessor: 'resolver',
        minWidth: 150,
        sortable: false,
        filterable: false,
        isShow: true,
      },
      {
        name: 'decision',
        Header: I18n.t('logPage.table.decision'),
        accessor: 'decision',
        sortable: false,
        filterable: false,
        isShow: true,
      },
      {
        name: 'dnsType',
        Header: I18n.t('logPage.table.dnsType'),
        accessor: 'dnsType',
        sortable: false,
        filterable: false,
        isShow: true,
      },
      {
        name: 'reason',
        Header: I18n.t('logPage.table.reason'),
        accessor: 'reason',
        sortable: false,
        filterable: false,
        isShow: true,
      },
      {
        name: 'policyName',
        Header: I18n.t('logPage.table.policyName'),
        accessor: 'policyName',
        minWidth: 150,
        sortable: false,
        filterable: false,
        isShow: true,
      },
      {
        name: 'apply',
        Header: I18n.t('logPage.table.apply'),
        accessor: 'apply',
        sortable: false,
        filterable: false,
        isShow: true,
      },
      {
        name: 'categories',
        Header: I18n.t('logPage.table.categories'),
        accessor: 'categories',
        Cell: this.categoriesCellRenderer,
        minWidth: 150,
        sortable: false,
        filterable: false,
        isShow: true,
      },
      {
        name: 'latency',
        Header: I18n.t('logPage.table.latency'),
        accessor: 'latency',
        sortable: false,
        filterable: false,
        isShow: true,
      },
      {
        name: 'networkIp',
        Header: I18n.t('logPage.table.networkIp'),
        accessor: 'networkIp',
        minWidth: 150,
        sortable: false,
        filterable: false,
        isShow: true,
      },
      {
        name: 'subnetIp',
        Header: I18n.t('logPage.table.subnetIp'),
        accessor: 'subnetIp',
        minWidth: 150,
        sortable: false,
        filterable: false,
        isShow: true,
      },
      {
        name: 'agentId',
        Header: I18n.t('logPage.table.agentId'),
        accessor: 'agentId',
        minWidth: 150,
        sortable: false,
        filterable: false,
        isShow: deviceAgentAllowed,
      },
      {
        name: 'clientUsername',
        Header: I18n.t('logPage.table.clientUsername'),
        accessor: 'clientUsername',
        minWidth: 150,
        sortable: false,
        filterable: false,
        isShow: deviceAgentAllowed,
      },
      {
        name: 'clientDomain',
        Header: I18n.t('logPage.table.clientDomain'),
        accessor: 'clientDomain',
        minWidth: 150,
        sortable: false,
        filterable: false,
        isShow: deviceAgentAllowed,
      },
      {
        name: 'clientHostname',
        Header: I18n.t('logPage.table.clientHostname'),
        accessor: 'clientHostname',
        minWidth: 150,
        sortable: false,
        filterable: false,
        isShow: deviceAgentAllowed,
      },
      {
        name: 'sourceType',
        Header: I18n.t('logPage.table.sourceType'),
        accessor: 'sourceType',
        minWidth: 150,
        sortable: false,
        filterable: false,
        isShow: true,
      },
      {
        name: 'policyType',
        Header: I18n.t('logPage.table.policyType'),
        accessor: 'policyType',
        minWidth: 150,
        sortable: false,
        filterable: false,
        isShow: true,
      },
    ];
    const headersObject = _reduce(
      headers,
      (result, header) => {result[header.name] = header; return result;},
      {},
    );
    const headersNames = headers.map((header) => header.name);

    const localStorageKey = props.customHeadersLocalStorageKey;
    let customHeaders = LS.getItem(localStorageKey);
    let tableHeaders = [];
    try {
      customHeaders = JSON.parse(customHeaders);
      tableHeaders = customHeaders.map((it) => ({
        ...headersObject[it.accessor],
        isShow: headersObject[it.accessor].isShow && it.isShow,
      }));
    } catch (e) {
      customHeaders = [];
      LS.setItem(
        localStorageKey,
        JSON.stringify(headersNames.map((_name) => ({
          accessor: headersObject[_name].accessor,
          isShow: true,
        }))),
      );
      tableHeaders = headers;
    }

    const pickerItems = headers.map((ho) => {
      const customHeader = _find(customHeaders, (ch) => ho.accessor === ch.accessor);
      const isShow = _get(customHeader, 'isShow', true);
      return {...ho, isShow, concealed: ho.isShow};
    });

    this.state = {
      headers: tableHeaders,
      pickerItems,
    };
  }

  getColumns = () => {
    const {headers} = this.state;
    return [{
      accessor: 'actions',
      filterable: false,
      sortable: false,
      width: 50,
      Cell: this.actionCellRenderer,
    },
    ...headers.filter((item) => item.isShow),
    ];
  };

  handleChangeHeadersPicker = (updatedHeaders) => {
    const {customHeadersLocalStorageKey} = this.props;
    this.setState({
      headers: updatedHeaders,
      pickerItems: updatedHeaders,
    });
    LS.setItem(
      customHeadersLocalStorageKey,
      JSON.stringify(updatedHeaders.map((header) => ({
        accessor: header.accessor,
        isShow: header.isShow,
      }))),
    );
  };

  render() {
    const {
      classes,
      fullScreenMode,
      headerActionNode,
      hasHeadersPicker,
      minRows,
      tableClassNames,
      tableData,
      tableStyles,
    } = this.props;
    const {pickerItems} = this.state;

    const cardClassName = classNames(
      classes.logTableCard,
      {
        [classes.logTableCard_fullScreen]: fullScreenMode,
      },
    );
    const cardHeaderStyle = {
      root: classes.logTableCard__header,
      title: classes.logTableCard__title,
    };
    const _tableClassNames = {
      tbody: classNames(
        classes.tbodyInfinite,
        {
          [classes.tbodyInfinite_fullScreen]: fullScreenMode,
          [classes.tbodyInfinite_noItems]: tableData.length === 0,
        },
        ...tableClassNames.tbody.map((it) => ({
          [classes[it.className]]: it.condition,
        })),
      ),
    };
    return (
      <>
        <Card className={cardClassName}>
          <CardHeader
            classes={cardHeaderStyle}
            title={I18n.t('logPage.table.title')}
            action={(
              <div className={classes.logTableCard__actions}>
                <RenderOrEmpty isShow={hasHeadersPicker}>
                  <TableColumnsPicker
                    headers={pickerItems}
                    onChange={this.handleChangeHeadersPicker}
                  />
                </RenderOrEmpty>
                {headerActionNode}
              </div>
            )}
          />
          <CardContent className={classes.logTableCard__content}>
            <LoadingLayout widgetKey={widgetKeys.LOG_TABLE}>
              <InfiniteTable
                columns={this.getColumns()}
                data={tableData}
                loadData={this.props.loadData}
                style={tableStyles}
                classNamesObj={_tableClassNames}
                minRows={minRows}
              />
            </LoadingLayout>
          </CardContent>
        </Card>
        <WbListsContainer />
      </>

    );
  }
}

LogTable.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  customHeadersLocalStorageKey: PropTypes.string.isRequired,
  fullScreenMode: PropTypes.bool,
  headerActionNode: PropTypes.node,
  hasHeadersPicker: PropTypes.bool,
  minRows: PropTypes.number.isRequired,
  tableClassNames: PropTypes.shape({
    tbody: PropTypes.arrayOf(PropTypes.shape({
      className: PropTypes.string.isRequired,
      condition: PropTypes.bool.isRequired,
    })),
  }),
  tableData: PropTypes.array,
  tableStyles: PropTypes.object,

  loadData: PropTypes.func,
  showResponse: PropTypes.func,
};

LogTable.defaultProps = {
  fullScreenMode: false,
  headerActionNode: null,
  hasHeadersPicker: false,
  tableClassNames: {
    tbody: [],
  },
  tableData: [],
  tableStyles: {},

  loadData: () => null,
  showResponse: () => {},
};

export default withStyles(style)(LogTable);
