import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';
import _isNull from 'lodash/isNull';

import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import {USERS_ON_SITE_STATUSES} from '../../app-common/Users/constants';
import {ENTER_KEY_CODE, USER_STATUSES} from '../../constants';
import {Close} from '../../icons';

import {Dropdown, PillsWithLabel} from '../../components';
import {CustomButton, CustomInput} from '../../material-dashboard-pro-react/components';
import {setSearchOption} from '../UsersManager/action';
import initReducerState from '../../reducers/redux-initial-state';

import style from './style';

class UsersControls extends React.Component {
  static initState = {
    selectedGroupIndex: 0,
    selectedStatusIndex: 0,
    selectedPersonaIndex: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...UsersControls.initState,
    };
    this.onSiteStatusPills = null;
    this.statusOptions = [
      I18n.t('users.usersManager.controls.filters.allStatusesOption'),
      ...Object.keys(USER_STATUSES),
    ];
  }

  componentDidMount() {
    const {groups, personas, searchOptions} = this.props;
    const selectedGroupIndex = _findIndex(
      groups,
      (group) => group.id === searchOptions.groupId,
    );
    const selectedPersonaIndex = _findIndex(
      personas,
      (persona) => persona.id === searchOptions.personaId,
    );
    this.setState({
      selectedGroupIndex: selectedGroupIndex + 1,
      selectedPersonaIndex: selectedPersonaIndex + 1,
    });
    const selectedStatusIndex = _findIndex(
      Object.keys(USER_STATUSES),
      (statusOption) => statusOption === searchOptions.userStatus,
    );
    this.setState({selectedStatusIndex: selectedStatusIndex + 1});
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(this.props.groups, prevProps.groups)) {
      const {groups, searchOptions} = this.props;
      const selectedGroupIndex = _findIndex(
        groups,
        (group) => group.id === searchOptions.groupId,
      );
      this.setState({selectedGroupIndex: selectedGroupIndex + 1});
    }
    if (!_isEqual(this.props.personas, prevProps.personas)) {
      const {personas, searchOptions} = this.props;
      const selectedPersonaIndex = _findIndex(
        personas,
        (persona) => persona.id === searchOptions.personaId,
      );
      this.setState({selectedPersonaIndex: selectedPersonaIndex + 1});
    }

    if (!_isEqual(this.props.searchOptions, prevProps.searchOptions)) {
      const {groups, personas, searchOptions} = this.props;
      const selectedGroupIndex = _findIndex(
        groups,
        (group) => group.id === searchOptions.groupId,
      );
      this.setState({selectedGroupIndex: selectedGroupIndex + 1});
      const selectedPersonaIndex = _findIndex(
        personas,
        (persona) => persona.id === searchOptions.personaId,
      );
      this.setState({selectedPersonaIndex: selectedPersonaIndex + 1});

      const selectedStatusIndex = _findIndex(
        Object.keys(USER_STATUSES),
        (statusOption) => statusOption === searchOptions.userStatus,
      );
      this.setState({selectedStatusIndex: selectedStatusIndex + 1});

      if (!_isNull(this.onSiteStatusPills)) {
        this.onSiteStatusPills.selectOption(searchOptions.onSiteStatus);
      }
    }
  }

  get userStatusControls() {
    return (
      <Dropdown
      options={this.statusOptions}
      selectedItemIndex={this.state.selectedStatusIndex}
      customSelectClass="dropdown__select_userStatus"
      customFormControlClass="dropdown__formControl_userGroup"
      onChangeValue={this.onStatusChange}
      />
    );
  }

  get onSiteControls() {
    const pills = {
      name: 'onSiteStatus',
      label: '',
      options: [
        {value: null, label: I18n.t('users.usersManager.controls.onSiteStatuses.BOTH')},
        ...Object.keys(USERS_ON_SITE_STATUSES).map((statusValue) => ({
          value: statusValue,
          label: I18n.t(`users.usersManager.controls.onSiteStatuses.${statusValue}`),
        }))],
      defaultValue: this.props.searchOptions.onSiteStatus,
      selectOption: this.props.setSearchOption,
      buttonsClassName: 'pills-buttons_users',
      onRef: (ref) => {this.onSiteStatusPills = ref;},
    };

    return (
      <PillsWithLabel
      {...pills}
      />
    );
  }

  get groupControls() {
    // TODO: remove it when Entra groups will be added to the filter
    return null;

//    const groupsOptions = this.props.groups.map((group) => group.name);
//    groupsOptions.unshift(
//      I18n.t('users.usersManager.controls.filters.allGroupsOption'));
//
//    return (
//      <Dropdown
//        options={groupsOptions}
//        selectedItemIndex={this.state.selectedGroupIndex}
//        customSelectClass="dropdown__select_userGroup"
//        onChangeValue={this.onGroupChange}
//      />
//    );
  }

  get personaControls() {
    const personasOptions = this.props.personas.map((persona) => persona.name);
    personasOptions.unshift(
      I18n.t('users.usersManager.controls.filters.allPersonasOption'));

    return (
      <Dropdown
        options={personasOptions}
        selectedItemIndex={this.state.selectedPersonaIndex}
        customSelectClass="dropdown__select_userPersona"
        onChangeValue={this.onPersonaChange}
      />
    );
  }

  get userSearch() {
    const {classes, searchOptions, setSearchOption} = this.props;
    const userFilterName = 'username';

    return (
      <CustomInput
        name={userFilterName}
        type="text"
        inputProps={{
          value: searchOptions.username,
          disabled: this.props.disabled,
          onChange: (e) => setSearchOption(userFilterName, e.target.value.toLowerCase()),
          onKeyPress: this.onUserNameSearchKeyPressed,
          placeholder: I18n.t('users.usersManager.controls.filters.placeholders.userName'),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setSearchOption(userFilterName, '')}
              >
                <Close />
              </IconButton>
            </InputAdornment>
          ),
        }}
        formControlProps={{
          className: classes.usersControls__userSearchInputForm,
        }}
      />
    );
  }

  onUserNameSearchKeyPressed = (e) => {
    if (e.charCode === ENTER_KEY_CODE) {
      this.props.onRefresh();
    }
  };

//  onGroupChange = (e) => {
//    const selectedGroupIndex = e.target.value;
//    this.setState({selectedGroupIndex});
//    let selectedGroupId = null;
//    if (selectedGroupIndex > 0) {
//      selectedGroupId = this.props.groups[selectedGroupIndex - 1].id;
//    }
//    this.props.setSearchOption('groupId', selectedGroupId);
//  };

  onPersonaChange = (e) => {
    const selectedPersonaIndex = e.target.value;
    this.setState({selectedPersonaIndex});
    let selectedPersonaId = null;
    if (selectedPersonaIndex > 0) {
      selectedPersonaId = this.props.personas[selectedPersonaIndex - 1].id;
    }
    this.props.setSearchOption('personaId', selectedPersonaId);
  };

  onStatusChange = (e) => {
    const selectedStatusIndex = e.target.value;
    this.setState({selectedStatusIndex});
    const statusOption = (selectedStatusIndex > 0) ? this.statusOptions[selectedStatusIndex] : null;
    this.props.setSearchOption('userStatus', statusOption);
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.onRefresh();
  };

  onReset = () => {
    if (!_isNull(this.onSiteStatusPills)) {
      this.onSiteStatusPills.selectOption(
        initReducerState.usersManagerReducer.searchOptions.onSiteStatus);
    }
    this.setState({selectedGroupIndex: 0});
    this.setState({selectedStatusIndex: 0});
    this.props.onReset();
  };

  render() {
    const {classes} = this.props;

    return (
      <form className={classes.usersControls}>
        <div className={classes.usersControls__row}>
          <div>
            {this.userStatusControls}
            {this.onSiteControls}
          </div>
          <div className={classes.usersControls__dropDownOptions}>
            {this.personaControls}
            {this.groupControls}
          </div>
        </div>
        <div className={classes.usersControls__row}>
          {this.userSearch}
          <div>
            <CustomButton
              type="submit"
              color="secondary"
              customClasses="users-controls__button"
              onClick={this.onSubmit}
              size="sm"
            >
              {I18n.t('buttons.submit').toUpperCase()}
            </CustomButton>
            <CustomButton
              color="gray"
              customClasses="users-controls__button"
              onClick={this.onReset}
              size="sm"
            >
              {I18n.t('buttons.reset').toUpperCase()}
            </CustomButton>
          </div>
        </div>
      </form>
    );
  }
}

UsersControls.propTypes = {
  classes: PropTypes.object.isRequired,
  searchOptions: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  personas: PropTypes.array.isRequired,
  setSearchOption: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

UsersControls.defaultProps = {
  disabled: false,
};

const mapStateToProps = (state) => ({
  searchOptions: state.usersManagerReducer.searchOptions,
  groups: state.usersManagerReducer.groupNames,
  personas: state.personaReducer.personas,
});

const mapDispatchToProps = {
  setSearchOption,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(UsersControls));
