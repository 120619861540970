import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {I18n} from 'react-redux-i18n';

import {addTenantToAccount} from '../../containers/UsersConfigurer/action';
import {openModalWindow} from '../../scout-dns/layouts/Operator/action';
import {MODAL_WINDOW_NOTIFY_TYPE} from '../../constants';
import {AddEntraTenantState} from './types';

const parseState = (state?: string | null) => {
  if (!state) {
    return null;
  }
  try {
    return JSON.parse(state);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Couldn\'t parse Entra state', state);
    return null;
  }
};

/**
 * Hook that handles adding tenant after redirecting from Entra.
 * Should be used on page with redirect URL
 * @param onComplete - function that will be called after completing the request
 * @returns object with field `state` - string that was passed to Entra, boolean `complete`
 * and function `clearParams`
 * which is a clean up function that should be called after everything is done
 */
function useAddEntraTenant() {
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const tenantId = urlParams.get('tenant');
  const state = urlParams.get('state');
  const adminConsent = urlParams.get('admin_consent');
  const error = urlParams.get('error');

  const [entraState, setEntraState] = useState<AddEntraTenantState | null>(parseState(state));

  const tenantAddedSuccess = useSelector((store: any) => store.personaReducer.tenantAddedSuccess);

  const clearParams = useCallback(() => {
    const keys = [...urlParams.keys()];
    keys.forEach((key) => urlParams.delete(key));
    const newUrl
      = `${window.location.pathname}${urlParams.toString() ? `?${urlParams.toString()}` : ''}`;
    window.history.replaceState({}, '', newUrl);
    setEntraState(null);
  }, [setEntraState]);

  useEffect(() => {
    const accountId = entraState?.accountId;
    if (accountId && tenantId && adminConsent?.toLowerCase() === 'true' && !error) {
      (addTenantToAccount(tenantId, accountId))(dispatch);
    } else if (error) {
      (openModalWindow(
        I18n.t('addTenant.noConsent'),
        MODAL_WINDOW_NOTIFY_TYPE.INFO,
      ))(dispatch);
    }
  }, [adminConsent, error]);

  useEffect(() => {
    if (tenantAddedSuccess) {
      (openModalWindow(
        I18n.t('addTenant.success'),
        MODAL_WINDOW_NOTIFY_TYPE.INFO,
      ))(dispatch);
    }
  }, [tenantAddedSuccess]);

  return {state: entraState, complete: tenantAddedSuccess !== null || !!error, clearParams};
}

export default useAddEntraTenant;
