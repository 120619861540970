import {createStyles} from '@material-ui/core';
import {defaultFont} from '../../styles/style';

const style = createStyles({
  header: {
    display: 'flex',
    gap: '16px',
    alignItems: 'start',
    justifyContent: 'space-between',
    marginTop: '12px',
  },
  header__title: {
    ...defaultFont,
    fontSize: '32px',
    margin: 0,
    lineHeight: 0,
  },
  header__text: {
    ...defaultFont,
  },
  header__group: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  header__group_a: {
    display: 'flex',
    alignItems: 'end',
    gap: '8px',
    flexDirection: 'column',
  },
  header__addTenantBlock: {
    display: 'block',
  },
  addTenantBlockContainer: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    width: '368px',
  },
  groupsContainer: {
    'display': 'flex',
    'gap': '16px',
    'alignItems': 'start',
    '@media (max-width: 1100px)': {
      flexDirection: 'column',
      alignItems: 'center',
      gap: 0,
    },
  },
  groupsContainer__item: {
    flex: 1,
  },
  dropdown: {
    minWidth: '200px',
  },
});

export default style;
